import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';
import { normalizedPegboard } from './pegboard_normalizer';

export function createCrocodile(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadSize: number
): Pegboard {
  return normalizedPegboard(manufacturer, manufacturerId, beadSize, [
    new Peg(0.01875, 0.34739),
    new Peg(0.03088, 0.31596),
    new Peg(0.0375, 0.37331),
    new Peg(0.04632, 0.86352),
    new Peg(0.04797, 0.17811),
    new Peg(0.04852, 0.14557),
    new Peg(0.05238, 0.34133),
    new Peg(0.05238, 0.83209),
    new Peg(0.05955, 0.89385),
    new Peg(0.06176, 0.30163),
    new Peg(0.06341, 0.39261),
    new Peg(0.07444, 0.80893),
    new Peg(0.07444, 0.19961),
    new Peg(0.0772, 0.36394),
    new Peg(0.07775, 0.16763),
    new Peg(0.0783, 0.86518),
    new Peg(0.07885, 0.13124),
    new Peg(0.08271, 0.33195),
    new Peg(0.09098, 0.89661),
    new Peg(0.09154, 0.83706),
    new Peg(0.09319, 0.30163),
    new Peg(0.09484, 0.39427),
    new Peg(0.0965, 0.22222),
    new Peg(0.10312, 0.18528),
    new Peg(0.10477, 0.15164),
    new Peg(0.10698, 0.80452),
    new Peg(0.10973, 0.86959),
    new Peg(0.11304, 0.33912),
    new Peg(0.11359, 0.37),
    new Peg(0.12297, 0.89881),
    new Peg(0.12352, 0.68376),
    new Peg(0.12352, 0.83926),
    new Peg(0.12407, 0.30769),
    new Peg(0.12793, 0.25034),
    new Peg(0.12848, 0.21891),
    new Peg(0.12958, 0.12462),
    new Peg(0.13179, 0.71464),
    new Peg(0.134, 0.18803),
    new Peg(0.1351, 0.65454),
    new Peg(0.1362, 0.1555),
    new Peg(0.13896, 0.80728),
    new Peg(0.14171, 0.87069),
    new Peg(0.14447, 0.34684),
    new Peg(0.15329, 0.73835),
    new Peg(0.1544, 0.83871),
    new Peg(0.15495, 0.31652),
    new Peg(0.15495, 0.681),
    new Peg(0.15881, 0.25586),
    new Peg(0.15991, 0.89881),
    new Peg(0.16046, 0.22388),
    new Peg(0.16046, 0.58175),
    new Peg(0.16101, 0.12958),
    new Peg(0.16267, 0.61538),
    new Peg(0.16322, 0.37276),
    new Peg(0.16377, 0.55087),
    new Peg(0.16598, 0.19245),
    new Peg(0.16708, 0.16046),
    new Peg(0.16873, 0.64902),
    new Peg(0.17039, 0.71023),
    new Peg(0.17425, 0.87014),
    new Peg(0.17701, 0.52219),
    new Peg(0.17811, 0.75931),
    new Peg(0.18252, 0.33085),
    new Peg(0.18583, 0.39427),
    new Peg(0.18638, 0.6777),
    new Peg(0.18693, 0.83871),
    new Peg(0.19079, 0.49132),
    new Peg(0.19134, 0.59553),
    new Peg(0.19134, 0.24924),
    new Peg(0.19245, 0.89881),
    new Peg(0.193, 0.56245),
    new Peg(0.19355, 0.73229),
    new Peg(0.19355, 0.21616),
    new Peg(0.1952, 0.36173),
    new Peg(0.19631, 0.63193),
    new Peg(0.19796, 0.14999),
    new Peg(0.19906, 0.18417),
    new Peg(0.20127, 0.78136),
    new Peg(0.20458, 0.70251),
    new Peg(0.20899, 0.53102),
    new Peg(0.20899, 0.86683),
    new Peg(0.2123, 0.6595),
    new Peg(0.2123, 0.4665),
    new Peg(0.2134, 0.33526),
    new Peg(0.21671, 0.75269),
    new Peg(0.21726, 0.39371),
    new Peg(0.22112, 0.50179),
    new Peg(0.22222, 0.23821),
    new Peg(0.22498, 0.56686),
    new Peg(0.22498, 0.89716),
    new Peg(0.22553, 0.20623),
    new Peg(0.22663, 0.13841),
    new Peg(0.22663, 0.59774),
    new Peg(0.22774, 0.72346),
    new Peg(0.22829, 0.79735),
    new Peg(0.22939, 0.17315),
    new Peg(0.23049, 0.36283),
    new Peg(0.23104, 0.44003),
    new Peg(0.23215, 0.62972),
    new Peg(0.2327, 0.68707),
    new Peg(0.23546, 0.82934),
    new Peg(0.23932, 0.53598),
    new Peg(0.24097, 0.86463),
    new Peg(0.24152, 0.47698),
    new Peg(0.24373, 0.32809),
    new Peg(0.24428, 0.77033),
    new Peg(0.24428, 0.6584),
    new Peg(0.24869, 0.3882),
    new Peg(0.252, 0.50675),
    new Peg(0.2531, 0.22774),
    new Peg(0.2542, 0.74056),
    new Peg(0.25531, 0.41908),
    new Peg(0.25531, 0.70913),
    new Peg(0.25531, 0.19631),
    new Peg(0.25641, 0.56576),
    new Peg(0.25641, 0.89606),
    new Peg(0.25696, 0.12903),
    new Peg(0.25862, 0.59719),
    new Peg(0.25917, 0.15991),
    new Peg(0.26027, 0.80618),
    new Peg(0.26082, 0.35732),
    new Peg(0.26413, 0.44941),
    new Peg(0.26578, 0.30494),
    new Peg(0.26689, 0.68045),
    new Peg(0.26689, 0.62752),
    new Peg(0.26799, 0.83761),
    new Peg(0.2713, 0.53763),
    new Peg(0.2735, 0.47918),
    new Peg(0.2735, 0.86793),
    new Peg(0.27461, 0.77805),
    new Peg(0.27736, 0.10532),
    new Peg(0.27957, 0.38599),
    new Peg(0.28012, 0.33251),
    new Peg(0.28288, 0.21395),
    new Peg(0.28343, 0.18087),
    new Peg(0.28398, 0.65398),
    new Peg(0.28508, 0.7174),
    new Peg(0.28508, 0.74828),
    new Peg(0.28508, 0.50786),
    new Peg(0.28784, 0.56686),
    new Peg(0.28839, 0.41687),
    new Peg(0.28894, 0.14999),
    new Peg(0.2906, 0.89826),
    new Peg(0.2917, 0.59884),
    new Peg(0.29225, 0.80838),
    new Peg(0.29611, 0.44886),
    new Peg(0.29722, 0.29887),
    new Peg(0.29777, 0.68652),
    new Peg(0.29942, 0.83926),
    new Peg(0.29942, 0.35842),
    new Peg(0.30273, 0.53984),
    new Peg(0.30273, 0.62752),
    new Peg(0.30383, 0.12131),
    new Peg(0.30438, 0.48029),
    new Peg(0.30549, 0.77971),
    new Peg(0.30659, 0.08768),
    new Peg(0.30659, 0.87014),
    new Peg(0.311, 0.3292),
    new Peg(0.31155, 0.38765),
    new Peg(0.31321, 0.20623),
    new Peg(0.31321, 0.17204),
    new Peg(0.31486, 0.66005),
    new Peg(0.31596, 0.51117),
    new Peg(0.31652, 0.7185),
    new Peg(0.31707, 0.74993),
    new Peg(0.31927, 0.56686),
    new Peg(0.31927, 0.41853),
    new Peg(0.31982, 0.05845),
    new Peg(0.32534, 0.59719),
    new Peg(0.3292, 0.4483),
    new Peg(0.32975, 0.68927),
    new Peg(0.32975, 0.14061),
    new Peg(0.3303, 0.30108),
    new Peg(0.3303, 0.02812),
    new Peg(0.33085, 0.36118),
    new Peg(0.33416, 0.53929),
    new Peg(0.33471, 0.62807),
    new Peg(0.33581, 0.10863),
    new Peg(0.33692, 0.47918),
    new Peg(0.34243, 0.3303),
    new Peg(0.34243, 0.39096),
    new Peg(0.34353, 0.2123),
    new Peg(0.34574, 0.07885),
    new Peg(0.34574, 0.18142),
    new Peg(0.34574, 0.27461),
    new Peg(0.34574, 0.65729),
    new Peg(0.34739, 0.72291),
    new Peg(0.34795, 0.75379),
    new Peg(0.3485, 0.51117),
    new Peg(0.3496, 0.24262),
    new Peg(0.35181, 0.56962),
    new Peg(0.35291, 0.42018),
    new Peg(0.35567, 0.04852),
    new Peg(0.35842, 0.6005),
    new Peg(0.35897, 0.14999),
    new Peg(0.35897, 0.01654),
    new Peg(0.36118, 0.45051),
    new Peg(0.36118, 0.30383),
    new Peg(0.36173, 0.68817),
    new Peg(0.36283, 0.36008),
    new Peg(0.36504, 0.11856),
    new Peg(0.36614, 0.54205),
    new Peg(0.36725, 0.86849),
    new Peg(0.3689, 0.48194),
    new Peg(0.37, 0.7753),
    new Peg(0.37055, 0.62917),
    new Peg(0.37386, 0.38985),
    new Peg(0.37607, 0.83816),
    new Peg(0.37607, 0.3314),
    new Peg(0.37662, 0.80673),
    new Peg(0.37662, 0.66005),
    new Peg(0.37717, 0.21395),
    new Peg(0.37717, 0.18197),
    new Peg(0.37717, 0.27681),
    new Peg(0.37772, 0.08988),
    new Peg(0.37772, 0.71629),
    new Peg(0.38048, 0.51337),
    new Peg(0.38103, 0.24483),
    new Peg(0.38213, 0.74662),
    new Peg(0.38379, 0.57182),
    new Peg(0.38434, 0.89495),
    new Peg(0.38489, 0.42128),
    new Peg(0.38599, 0.059),
    new Peg(0.3882, 0.02812),
    new Peg(0.39096, 0.15109),
    new Peg(0.39261, 0.45272),
    new Peg(0.39261, 0.30383),
    new Peg(0.39316, 0.6016),
    new Peg(0.39371, 0.35787),
    new Peg(0.39592, 0.11966),
    new Peg(0.39647, 0.69148),
    new Peg(0.39868, 0.54315),
    new Peg(0.39923, 0.86573),
    new Peg(0.40088, 0.48304),
    new Peg(0.40199, 0.77144),
    new Peg(0.40364, 0.63248),
    new Peg(0.40529, 0.38655),
    new Peg(0.40695, 0.66336),
    new Peg(0.40805, 0.33085),
    new Peg(0.40805, 0.80287),
    new Peg(0.4086, 0.20954),
    new Peg(0.4086, 0.8354),
    new Peg(0.4086, 0.72015),
    new Peg(0.4097, 0.2735),
    new Peg(0.41026, 0.17756),
    new Peg(0.41026, 0.07885),
    new Peg(0.41191, 0.24262),
    new Peg(0.41301, 0.51503),
    new Peg(0.41522, 0.04467),
    new Peg(0.41687, 0.89495),
    new Peg(0.41742, 0.41522),
    new Peg(0.42018, 0.56796),
    new Peg(0.42184, 0.14337),
    new Peg(0.42294, 0.44665),
    new Peg(0.42349, 0.30108),
    new Peg(0.42459, 0.10698),
    new Peg(0.42514, 0.59994),
    new Peg(0.42514, 0.35842),
    new Peg(0.42735, 0.74552),
    new Peg(0.42735, 0.69369),
    new Peg(0.43176, 0.86518),
    new Peg(0.43231, 0.47753),
    new Peg(0.43286, 0.77585),
    new Peg(0.43397, 0.54039),
    new Peg(0.43452, 0.62917),
    new Peg(0.43672, 0.6606),
    new Peg(0.43783, 0.38765),
    new Peg(0.43893, 0.3292),
    new Peg(0.43893, 0.20292),
    new Peg(0.44003, 0.26634),
    new Peg(0.44058, 0.17149),
    new Peg(0.44169, 0.2349),
    new Peg(0.44224, 0.06121),
    new Peg(0.44279, 0.8365),
    new Peg(0.44279, 0.80507),
    new Peg(0.44389, 0.50951),
    new Peg(0.4461, 0.71905),
    new Peg(0.44665, 0.02812),
    new Peg(0.4483, 0.12793),
    new Peg(0.4483, 0.8933),
    new Peg(0.45216, 0.09319),
    new Peg(0.45327, 0.45161),
    new Peg(0.45382, 0.29391),
    new Peg(0.45382, 0.56576),
    new Peg(0.45658, 0.59774),
    new Peg(0.45823, 0.35622),
    new Peg(0.45823, 0.75159),
    new Peg(0.46044, 0.69093),
    new Peg(0.4643, 0.48525),
    new Peg(0.46485, 0.53322),
    new Peg(0.4654, 0.62862),
    new Peg(0.4654, 0.86518),
    new Peg(0.46595, 0.78246),
    new Peg(0.4665, 0.6606),
    new Peg(0.4676, 0.39537),
    new Peg(0.4676, 0.21505),
    new Peg(0.46816, 0.15274),
    new Peg(0.46871, 0.32093),
    new Peg(0.46981, 0.18362),
    new Peg(0.47422, 0.04797),
    new Peg(0.47698, 0.1158),
    new Peg(0.47753, 0.01599),
    new Peg(0.47808, 0.72401),
    new Peg(0.48029, 0.0794),
    new Peg(0.48139, 0.89495),
    new Peg(0.4836, 0.45988),
    new Peg(0.4858, 0.55804),
    new Peg(0.48635, 0.36945),
    new Peg(0.48746, 0.59112),
    new Peg(0.48911, 0.83706),
    new Peg(0.48966, 0.75931),
    new Peg(0.49187, 0.69369),
    new Peg(0.49407, 0.33967),
    new Peg(0.49628, 0.62255),
    new Peg(0.49683, 0.65454),
    new Peg(0.49738, 0.14006),
    new Peg(0.49793, 0.40585),
    new Peg(0.49793, 0.20347),
    new Peg(0.49848, 0.17204),
    new Peg(0.49904, 0.52936),
    new Peg(0.49959, 0.86628),
    new Peg(0.504, 0.03364),
    new Peg(0.50455, 0.10201),
    new Peg(0.50786, 0.06507),
    new Peg(0.50786, 0.73394),
    new Peg(0.51392, 0.8944),
    new Peg(0.51558, 0.57568),
    new Peg(0.51613, 0.37883),
    new Peg(0.51889, 0.6777),
    new Peg(0.52219, 0.8354),
    new Peg(0.5233, 0.60601),
    new Peg(0.52385, 0.34905),
    new Peg(0.5244, 0.70802),
    new Peg(0.5255, 0.12738),
    new Peg(0.52716, 0.15936),
    new Peg(0.52716, 0.64516),
    new Peg(0.52716, 0.19189),
    new Peg(0.52881, 0.54756),
    new Peg(0.52936, 0.51613),
    new Peg(0.53102, 0.08602),
    new Peg(0.53102, 0.7582),
    new Peg(0.53212, 0.4064),
    new Peg(0.53267, 0.86573),
    new Peg(0.53322, 0.80618),
    new Peg(0.54591, 0.73008),
    new Peg(0.54701, 0.8933),
    new Peg(0.54811, 0.58726),
    new Peg(0.54866, 0.66832),
    new Peg(0.54866, 0.37497),
    new Peg(0.54977, 0.62255),
    new Peg(0.55142, 0.11028),
    new Peg(0.55307, 0.83485),
    new Peg(0.55418, 0.7003),
    new Peg(0.55473, 0.34353),
    new Peg(0.55583, 0.17204),
    new Peg(0.55859, 0.14116),
    new Peg(0.56355, 0.86463),
    new Peg(0.5641, 0.7582),
    new Peg(0.56465, 0.80562),
    new Peg(0.56686, 0.40033),
    new Peg(0.57017, 0.64626),
    new Peg(0.57734, 0.60656),
    new Peg(0.57789, 0.72512),
    new Peg(0.57789, 0.57458),
    new Peg(0.57789, 0.68045),
    new Peg(0.58009, 0.8933),
    new Peg(0.5812, 0.36669),
    new Peg(0.58451, 0.8343),
    new Peg(0.58506, 0.33361),
    new Peg(0.59498, 0.86407),
    new Peg(0.59553, 0.76427),
    new Peg(0.59608, 0.80562),
    new Peg(0.59829, 0.63193),
    new Peg(0.60105, 0.70471),
    new Peg(0.60601, 0.66667),
    new Peg(0.60822, 0.73559),
    new Peg(0.60877, 0.59939),
    new Peg(0.60932, 0.56851),
    new Peg(0.61152, 0.35787),
    new Peg(0.61263, 0.89054),
    new Peg(0.61594, 0.32479),
    new Peg(0.61704, 0.83044),
    new Peg(0.61924, 0.3882),
    new Peg(0.6231, 0.42018),
    new Peg(0.62421, 0.77695),
    new Peg(0.62752, 0.64296),
    new Peg(0.62807, 0.86022),
    new Peg(0.63248, 0.71243),
    new Peg(0.63524, 0.68045),
    new Peg(0.63689, 0.74828),
    new Peg(0.64296, 0.36228),
    new Peg(0.64737, 0.32975),
    new Peg(0.65068, 0.39592),
    new Peg(0.65398, 0.78632),
    new Peg(0.65564, 0.65674),
    new Peg(0.65564, 0.42735),
    new Peg(0.66115, 0.72512),
    new Peg(0.66336, 0.62531),
    new Peg(0.66501, 0.69313),
    new Peg(0.66887, 0.75875),
    new Peg(0.67439, 0.37331),
    new Peg(0.67714, 0.34078),
    new Peg(0.68156, 0.80176),
    new Peg(0.68431, 0.66887),
    new Peg(0.68486, 0.41687),
    new Peg(0.68983, 0.60766),
    new Peg(0.69313, 0.72843),
    new Peg(0.69424, 0.6391),
    new Peg(0.69755, 0.69755),
    new Peg(0.6992, 0.77364),
    new Peg(0.70141, 0.39041),
    new Peg(0.70306, 0.35842),
    new Peg(0.70968, 0.81776),
    new Peg(0.71905, 0.74662),
    new Peg(0.72732, 0.79184),
    new Peg(0.72787, 0.71299),
    new Peg(0.74056, 0.82492),
    new Peg(0.74331, 0.68597),
    new Peg(0.74387, 0.76592),
    new Peg(0.75159, 0.73394),
    new Peg(0.75875, 0.79294),
    new Peg(0.77089, 0.83209),
    new Peg(0.77309, 0.70968),
    new Peg(0.77364, 0.67659),
    new Peg(0.77419, 0.75765),
    new Peg(0.79018, 0.80011),
    new Peg(0.80287, 0.8365),
    new Peg(0.80342, 0.77089),
    new Peg(0.82051, 0.80948),
    new Peg(0.82713, 0.74883),
    new Peg(0.8343, 0.77971),
    new Peg(0.8354, 0.83761),
    new Peg(0.8525, 0.80838),
    new Peg(0.85966, 0.74111),
    new Peg(0.86628, 0.77199),
    new Peg(0.86683, 0.83926),
    new Peg(0.88393, 0.80948),
    new Peg(0.89826, 0.83816),
    new Peg(0.91536, 0.80618),
    new Peg(0.91756, 0.77364),
    new Peg(0.93466, 0.83209),
    new Peg(0.94348, 0.79349),
    new Peg(0.95506, 0.72787),
    new Peg(0.96443, 0.81776),
    new Peg(0.97215, 0.78191),
    new Peg(0.97712, 0.75159),
  ]);
}
