import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';

export function createNonInterleavedRectangle(
  manufacturer: Manufacturer,
  manufacturerId: string,
  width: number,
  height: number,
  beadDiameter: number
): Pegboard {
  const pegs: Array<Peg> = [];
  for (let iy = 1; iy < height + 1; iy++) {
    for (let ix = 1; ix < width + 1; ix++) {
      pegs.push(new Peg(ix * beadDiameter, iy * beadDiameter));
    }
  }
  return new Pegboard(manufacturer, manufacturerId, pegs, beadDiameter, 0, 45);
}
