import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';
import { normalizedPegboard } from './pegboard_normalizer';

export function createTeddy(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadSize: number
): Pegboard {
  return normalizedPegboard(manufacturer, manufacturerId, beadSize, [
    new Peg(0.06782, 0.68153),
    new Peg(0.0864, 0.63322),
    new Peg(0.08863, 0.72464),
    new Peg(0.11613, 0.67484),
    new Peg(0.11613, 0.59383),
    new Peg(0.12505, 0.75883),
    new Peg(0.14065, 0.63471),
    new Peg(0.1414, 0.71572),
    new Peg(0.1689, 0.58714),
    new Peg(0.17113, 0.67633),
    new Peg(0.17336, 0.76626),
    new Peg(0.18822, 0.63099),
    new Peg(0.19045, 0.72018),
    new Peg(0.19342, 0.16128),
    new Peg(0.20531, 1.123),
    new Peg(0.21126, 1.16908),
    new Peg(0.212, 0.20587),
    new Peg(0.21646, 0.5864),
    new Peg(0.21869, 0.11371),
    new Peg(0.22092, 0.6845),
    new Peg(0.22761, 0.75139),
    new Peg(0.23504, 0.62951),
    new Peg(0.23802, 0.24823),
    new Peg(0.24099, 0.16128),
    new Peg(0.24247, 1.07023),
    new Peg(0.25437, 1.12226),
    new Peg(0.25585, 0.07358),
    new Peg(0.25957, 0.20513),
    new Peg(0.26106, 1.17057),
    new Peg(0.26254, 0.71944),
    new Peg(0.26477, 0.6689),
    new Peg(0.26477, 0.58045),
    new Peg(0.2722, 0.34931),
    new Peg(0.27443, 0.12114),
    new Peg(0.27666, 0.30026),
    new Peg(0.27815, 0.39688),
    new Peg(0.28112, 0.62356),
    new Peg(0.28558, 0.24972),
    new Peg(0.29004, 0.96841),
    new Peg(0.29153, 1.01747),
    new Peg(0.29227, 0.44444),
    new Peg(0.29376, 1.06949),
    new Peg(0.29376, 0.16871),
    new Peg(0.29822, 0.76106),
    new Peg(0.30342, 1.1178),
    new Peg(0.30565, 0.80862),
    new Peg(0.30639, 0.08175),
    new Peg(0.31011, 0.56559),
    new Peg(0.31011, 1.16908),
    new Peg(0.31159, 0.92233),
    new Peg(0.31159, 0.71423),
    new Peg(0.31308, 0.66667),
    new Peg(0.31382, 0.21182),
    new Peg(0.31977, 0.356),
    new Peg(0.32349, 0.85544),
    new Peg(0.32423, 0.40654),
    new Peg(0.32497, 0.30398),
    new Peg(0.32646, 0.12709),
    new Peg(0.32795, 0.62207),
    new Peg(0.33389, 0.46897),
    new Peg(0.33389, 0.52025),
    new Peg(0.33389, 0.25715),
    new Peg(0.34207, 1.06875),
    new Peg(0.34355, 0.9699),
    new Peg(0.34355, 1.01747),
    new Peg(0.3443, 0.17094),
    new Peg(0.35098, 1.12152),
    new Peg(0.35247, 0.77072),
    new Peg(0.35247, 0.89632),
    new Peg(0.35396, 0.81754),
    new Peg(0.35619, 0.5812),
    new Peg(0.35693, 0.0877),
    new Peg(0.35916, 0.6741),
    new Peg(0.3599, 0.72315),
    new Peg(0.3599, 1.16908),
    new Peg(0.36436, 0.21553),
    new Peg(0.36511, 0.38127),
    new Peg(0.36585, 0.33445),
    new Peg(0.36659, 0.42958),
    new Peg(0.37551, 0.63025),
    new Peg(0.377, 0.13527),
    new Peg(0.377, 0.93571),
    new Peg(0.37923, 0.85619),
    new Peg(0.37997, 0.48606),
    new Peg(0.38146, 0.5366),
    new Peg(0.38294, 0.25938),
    new Peg(0.39186, 1.07023),
    new Peg(0.39186, 1.02415),
    new Peg(0.39483, 0.97808),
    new Peg(0.39781, 1.11706),
    new Peg(0.40004, 0.1806),
    new Peg(0.40078, 0.30323),
    new Peg(0.40152, 0.81605),
    new Peg(0.40301, 0.59086),
    new Peg(0.40375, 0.76849),
    new Peg(0.40524, 0.67559),
    new Peg(0.40747, 0.90078),
    new Peg(0.40747, 0.72166),
    new Peg(0.40747, 0.40282),
    new Peg(0.40747, 0.35452),
    new Peg(0.40896, 1.16388),
    new Peg(0.4097, 0.45039),
    new Peg(0.42233, 0.11669),
    new Peg(0.42679, 0.63471),
    new Peg(0.42754, 0.50316),
    new Peg(0.42754, 0.21999),
    new Peg(0.42828, 0.85916),
    new Peg(0.42828, 0.55147),
    new Peg(0.42977, 0.94537),
    new Peg(0.432, 0.26904),
    new Peg(0.44166, 1.04199),
    new Peg(0.4424, 1.0903),
    new Peg(0.44314, 0.16128),
    new Peg(0.44463, 0.99517),
    new Peg(0.44537, 0.3233),
    new Peg(0.44909, 1.13861),
    new Peg(0.45132, 0.41918),
    new Peg(0.45281, 0.37012),
    new Peg(0.45281, 0.77443),
    new Peg(0.45281, 0.59532),
    new Peg(0.45355, 0.82051),
    new Peg(0.45429, 0.466),
    new Peg(0.45429, 0.68153),
    new Peg(0.45504, 0.72761),
    new Peg(0.45504, 0.90524),
    new Peg(0.47064, 0.11148),
    new Peg(0.47287, 0.63842),
    new Peg(0.4751, 0.28837),
    new Peg(0.47585, 0.24155),
    new Peg(0.47585, 0.95578),
    new Peg(0.47733, 0.19398),
    new Peg(0.47733, 0.50836),
    new Peg(0.47733, 0.55444),
    new Peg(0.47808, 0.86362),
    new Peg(0.49889, 0.15013),
    new Peg(0.49963, 0.33073),
    new Peg(0.50037, 0.67781),
    new Peg(0.50111, 0.37755),
    new Peg(0.50111, 0.59829),
    new Peg(0.50111, 0.42438),
    new Peg(0.50111, 0.46823),
    new Peg(0.50186, 0.91044),
    new Peg(0.50186, 0.82126),
    new Peg(0.50186, 0.77295),
    new Peg(0.50186, 0.72538),
    new Peg(0.52415, 0.86362),
    new Peg(0.5249, 0.55444),
    new Peg(0.5249, 0.50836),
    new Peg(0.5249, 0.19398),
    new Peg(0.52564, 0.28911),
    new Peg(0.52638, 0.95578),
    new Peg(0.52638, 0.24155),
    new Peg(0.52936, 0.63842),
    new Peg(0.53159, 0.11148),
    new Peg(0.54794, 0.68153),
    new Peg(0.54794, 0.822),
    new Peg(0.54868, 0.72984),
    new Peg(0.54868, 0.90375),
    new Peg(0.54868, 0.46823),
    new Peg(0.54942, 0.59532),
    new Peg(0.54942, 0.37012),
    new Peg(0.55017, 0.77592),
    new Peg(0.55091, 0.41918),
    new Peg(0.55314, 1.13861),
    new Peg(0.55686, 0.3233),
    new Peg(0.5576, 0.99517),
    new Peg(0.55909, 0.16128),
    new Peg(0.55983, 1.0903),
    new Peg(0.56057, 1.04199),
    new Peg(0.57023, 0.26904),
    new Peg(0.57246, 0.94537),
    new Peg(0.57321, 0.86065),
    new Peg(0.57395, 0.55147),
    new Peg(0.57469, 0.21999),
    new Peg(0.57544, 0.63471),
    new Peg(0.5799, 0.11669),
    new Peg(0.58138, 0.50613),
    new Peg(0.59327, 1.16388),
    new Peg(0.59402, 0.45262),
    new Peg(0.59402, 0.81605),
    new Peg(0.59476, 0.35452),
    new Peg(0.59476, 0.72166),
    new Peg(0.59476, 0.40282),
    new Peg(0.59699, 0.67559),
    new Peg(0.59848, 0.90078),
    new Peg(0.59848, 0.76849),
    new Peg(0.59922, 0.59086),
    new Peg(0.60145, 0.30323),
    new Peg(0.60219, 0.1806),
    new Peg(0.60442, 1.11706),
    new Peg(0.6074, 0.97808),
    new Peg(0.61037, 1.02415),
    new Peg(0.61037, 1.07023),
    new Peg(0.61929, 0.25938),
    new Peg(0.62077, 0.5366),
    new Peg(0.623, 0.85619),
    new Peg(0.62523, 0.48978),
    new Peg(0.62523, 0.13527),
    new Peg(0.62672, 0.63025),
    new Peg(0.62895, 0.93497),
    new Peg(0.63564, 0.42958),
    new Peg(0.63638, 0.33445),
    new Peg(0.63712, 0.38127),
    new Peg(0.63787, 0.21553),
    new Peg(0.64233, 1.16908),
    new Peg(0.64233, 0.72315),
    new Peg(0.64307, 0.6741),
    new Peg(0.6453, 0.0877),
    new Peg(0.64604, 0.5812),
    new Peg(0.64827, 0.81754),
    new Peg(0.64976, 0.77072),
    new Peg(0.65124, 1.12152),
    new Peg(0.65422, 0.89632),
    new Peg(0.65793, 0.17094),
    new Peg(0.65868, 0.9699),
    new Peg(0.65868, 1.01747),
    new Peg(0.66016, 1.06875),
    new Peg(0.66834, 0.52025),
    new Peg(0.66834, 0.25715),
    new Peg(0.66834, 0.46897),
    new Peg(0.67428, 0.62207),
    new Peg(0.67577, 0.12709),
    new Peg(0.67726, 0.30398),
    new Peg(0.678, 0.40654),
    new Peg(0.68246, 0.356),
    new Peg(0.6832, 0.85619),
    new Peg(0.68841, 0.21182),
    new Peg(0.68915, 0.66667),
    new Peg(0.69064, 0.71423),
    new Peg(0.69212, 1.16908),
    new Peg(0.69212, 0.56559),
    new Peg(0.69435, 0.92382),
    new Peg(0.69584, 0.08175),
    new Peg(0.69658, 0.80862),
    new Peg(0.69881, 1.1178),
    new Peg(0.70401, 0.76106),
    new Peg(0.70847, 1.06949),
    new Peg(0.70847, 0.16871),
    new Peg(0.70996, 0.44444),
    new Peg(0.7107, 1.01747),
    new Peg(0.71219, 0.96841),
    new Peg(0.71665, 0.24972),
    new Peg(0.72111, 0.62356),
    new Peg(0.72408, 0.39688),
    new Peg(0.72557, 0.30026),
    new Peg(0.7278, 0.12114),
    new Peg(0.73003, 0.34931),
    new Peg(0.73746, 0.58045),
    new Peg(0.73746, 0.6689),
    new Peg(0.73969, 0.71944),
    new Peg(0.74117, 1.17057),
    new Peg(0.74266, 0.20513),
    new Peg(0.74638, 0.07358),
    new Peg(0.74786, 1.12226),
    new Peg(0.75975, 1.07023),
    new Peg(0.76124, 0.16128),
    new Peg(0.76421, 0.24823),
    new Peg(0.76793, 0.63099),
    new Peg(0.77462, 0.75139),
    new Peg(0.78131, 0.6845),
    new Peg(0.78354, 0.11371),
    new Peg(0.78577, 0.5864),
    new Peg(0.79023, 0.20587),
    new Peg(0.79097, 1.16908),
    new Peg(0.79692, 1.123),
    new Peg(0.80881, 0.16128),
    new Peg(0.81178, 0.72018),
    new Peg(0.81401, 0.63248),
    new Peg(0.82887, 0.76626),
    new Peg(0.8311, 0.67707),
    new Peg(0.83333, 0.58714),
    new Peg(0.86083, 0.71572),
    new Peg(0.86158, 0.63471),
    new Peg(0.87718, 0.75883),
    new Peg(0.8861, 0.67484),
    new Peg(0.8861, 0.59383),
    new Peg(0.9136, 0.72464),
    new Peg(0.91583, 0.63322),
    new Peg(0.93441, 0.68153),
  ]);
}
