import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';
import { normalizedPegboard } from './pegboard_normalizer';

export function createPrincess(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadSize: number
): Pegboard {
  return normalizedPegboard(manufacturer, manufacturerId, beadSize, [
    new Peg(0.02724, 0.57867),
    new Peg(0.0421, 0.53987),
    new Peg(0.05035, 1.20852),
    new Peg(0.05201, 0.61417),
    new Peg(0.06274, 1.24732),
    new Peg(0.06521, 1.1689),
    new Peg(0.0776, 0.57372),
    new Peg(0.08255, 0.52749),
    new Peg(0.09246, 1.27786),
    new Peg(0.09246, 0.61829),
    new Peg(0.09576, 1.14248),
    new Peg(0.10154, 1.19696),
    new Peg(0.10484, 1.23741),
    new Peg(0.10731, 1.10203),
    new Peg(0.11557, 0.5019),
    new Peg(0.11805, 0.58527),
    new Peg(0.123, 0.544),
    new Peg(0.1296, 1.29354),
    new Peg(0.1296, 1.06736),
    new Peg(0.1296, 1.16559),
    new Peg(0.13951, 1.211),
    new Peg(0.14116, 1.12597),
    new Peg(0.14198, 1.2531),
    new Peg(0.14281, 1.02609),
    new Peg(0.15519, 0.60591),
    new Peg(0.15849, 0.56381),
    new Peg(0.16015, 0.98729),
    new Peg(0.1684, 1.0946),
    new Peg(0.16923, 1.18128),
    new Peg(0.17253, 1.30675),
    new Peg(0.17666, 1.05415),
    new Peg(0.17748, 1.14248),
    new Peg(0.17996, 1.2242),
    new Peg(0.17996, 1.2663),
    new Peg(0.18574, 0.95674),
    new Peg(0.19069, 1.0137),
    new Peg(0.19069, 0.62655),
    new Peg(0.19234, 0.5861),
    new Peg(0.20307, 0.92042),
    new Peg(0.20967, 1.19366),
    new Peg(0.2105, 1.11111),
    new Peg(0.21298, 1.32161),
    new Peg(0.21463, 1.07066),
    new Peg(0.21545, 1.15404),
    new Peg(0.2171, 1.28034),
    new Peg(0.21793, 0.98233),
    new Peg(0.21793, 1.23824),
    new Peg(0.21958, 0.88328),
    new Peg(0.22701, 1.03021),
    new Peg(0.22866, 0.64223),
    new Peg(0.23196, 0.59766),
    new Peg(0.23609, 0.94684),
    new Peg(0.24104, 0.8486),
    new Peg(0.25095, 1.21017),
    new Peg(0.25177, 1.33234),
    new Peg(0.2526, 0.90804),
    new Peg(0.2526, 1.12762),
    new Peg(0.25425, 1.08222),
    new Peg(0.25673, 1.29189),
    new Peg(0.25673, 1.25062),
    new Peg(0.25755, 0.99967),
    new Peg(0.25755, 1.16807),
    new Peg(0.26168, 0.81311),
    new Peg(0.26498, 0.40532),
    new Peg(0.26663, 1.04177),
    new Peg(0.26828, 0.62655),
    new Peg(0.26994, 0.57372),
    new Peg(0.26994, 0.36157),
    new Peg(0.27324, 0.87254),
    new Peg(0.27489, 0.96087),
    new Peg(0.28645, 0.77761),
    new Peg(0.29057, 0.32689),
    new Peg(0.29057, 0.92372),
    new Peg(0.2914, 1.22008),
    new Peg(0.29222, 1.13918),
    new Peg(0.29222, 1.09625),
    new Peg(0.2947, 1.34307),
    new Peg(0.29553, 0.54152),
    new Peg(0.29553, 1.17963),
    new Peg(0.29553, 0.83622),
    new Peg(0.29635, 1.26218),
    new Peg(0.29718, 1.30263),
    new Peg(0.298, 1.01288),
    new Peg(0.30213, 0.43173),
    new Peg(0.30296, 1.0558),
    new Peg(0.30461, 0.60261),
    new Peg(0.30543, 0.38963),
    new Peg(0.30626, 0.50025),
    new Peg(0.31121, 0.88823),
    new Peg(0.31204, 0.97491),
    new Peg(0.31947, 0.80155),
    new Peg(0.32029, 0.75037),
    new Peg(0.32937, 0.56959),
    new Peg(0.33102, 0.93446),
    new Peg(0.33267, 1.14826),
    new Peg(0.33267, 1.10781),
    new Peg(0.3335, 0.85521),
    new Peg(0.33432, 1.18788),
    new Peg(0.33515, 1.35463),
    new Peg(0.33515, 1.22833),
    new Peg(0.3368, 1.31336),
    new Peg(0.3368, 1.26961),
    new Peg(0.3368, 0.52831),
    new Peg(0.33845, 1.02443),
    new Peg(0.34175, 1.06736),
    new Peg(0.34175, 0.47961),
    new Peg(0.34506, 0.42926),
    new Peg(0.34753, 0.719),
    new Peg(0.34918, 0.38055),
    new Peg(0.35166, 0.98233),
    new Peg(0.35331, 0.89979),
    new Peg(0.35414, 0.77596),
    new Peg(0.35661, 0.81806),
    new Peg(0.36817, 0.5539),
    new Peg(0.36817, 0.26251),
    new Peg(0.3723, 0.30708),
    new Peg(0.3723, 0.51015),
    new Peg(0.37312, 0.86264),
    new Peg(0.37395, 1.19614),
    new Peg(0.37477, 1.15651),
    new Peg(0.37477, 0.94271),
    new Peg(0.37477, 0.21958),
    new Peg(0.3756, 0.05201),
    new Peg(0.3756, 1.11441),
    new Peg(0.37642, 1.36123),
    new Peg(0.37642, 1.31996),
    new Peg(0.37725, 1.27869),
    new Peg(0.37807, 1.03186),
    new Peg(0.3789, 1.23659),
    new Peg(0.37973, 0.74542),
    new Peg(0.38055, 1.07396),
    new Peg(0.38138, 0.46888),
    new Peg(0.38138, 0.34671),
    new Peg(0.3855, 0.42595),
    new Peg(0.38881, 0.18078),
    new Peg(0.39128, 0.3855),
    new Peg(0.39211, 0.70579),
    new Peg(0.39458, 0.78669),
    new Peg(0.39458, 0.90721),
    new Peg(0.39624, 0.82797),
    new Peg(0.39706, 0.98976),
    new Peg(0.39871, 0.58197),
    new Peg(0.40449, 0.53492),
    new Peg(0.40449, 0.0809),
    new Peg(0.4144, 0.28232),
    new Peg(0.41522, 1.11772),
    new Peg(0.41522, 0.6736),
    new Peg(0.41522, 0.87007),
    new Peg(0.41687, 1.20439),
    new Peg(0.41687, 1.16064),
    new Peg(0.4177, 1.32739),
    new Peg(0.4177, 1.28529),
    new Peg(0.4177, 0.95014),
    new Peg(0.41852, 0.7545),
    new Peg(0.41852, 1.36866),
    new Peg(0.41935, 0.45485),
    new Peg(0.41935, 1.24567),
    new Peg(0.41935, 1.03434),
    new Peg(0.42018, 0.24187),
    new Peg(0.42018, 1.07561),
    new Peg(0.421, 0.49695),
    new Peg(0.42265, 0.32442),
    new Peg(0.42265, 0.15767),
    new Peg(0.42265, 0.11639),
    new Peg(0.4243, 0.19977),
    new Peg(0.42678, 0.41192),
    new Peg(0.4276, 0.36404),
    new Peg(0.43503, 0.71653),
    new Peg(0.43668, 0.83375),
    new Peg(0.43668, 0.90804),
    new Peg(0.43751, 0.7933),
    new Peg(0.43834, 0.59518),
    new Peg(0.43916, 0.99141),
    new Peg(0.43999, 0.64141),
    new Peg(0.44246, 0.55143),
    new Peg(0.45732, 1.32987),
    new Peg(0.45732, 1.20357),
    new Peg(0.45732, 0.95427),
    new Peg(0.45815, 1.28612),
    new Peg(0.45815, 0.22123),
    new Peg(0.45897, 0.75863),
    new Peg(0.45897, 1.24567),
    new Peg(0.45897, 1.11937),
    new Peg(0.45897, 1.03764),
    new Peg(0.45897, 1.16147),
    new Peg(0.45897, 0.87502),
    new Peg(0.4598, 0.68103),
    new Peg(0.4598, 0.09823),
    new Peg(0.46062, 1.37114),
    new Peg(0.46062, 1.07892),
    new Peg(0.46145, 0.26168),
    new Peg(0.46145, 0.17996),
    new Peg(0.46145, 0.13951),
    new Peg(0.4631, 0.30213),
    new Peg(0.4631, 0.43008),
    new Peg(0.4631, 0.34258),
    new Peg(0.4631, 0.51346),
    new Peg(0.4631, 0.38798),
    new Peg(0.46393, 0.47218),
    new Peg(0.47631, 0.91795),
    new Peg(0.47713, 0.72148),
    new Peg(0.47796, 0.7999),
    new Peg(0.47878, 0.06109),
    new Peg(0.47878, 0.84035),
    new Peg(0.48044, 0.99719),
    new Peg(0.48044, 0.60343),
    new Peg(0.48044, 0.56298),
    new Peg(0.48126, 0.64471),
    new Peg(0.49942, 1.25144),
    new Peg(0.50025, 0.95674),
    new Peg(0.50025, 1.07974),
    new Peg(0.50025, 1.03599),
    new Peg(0.50107, 0.68516),
    new Peg(0.50107, 0.76275),
    new Peg(0.50107, 0.87667),
    new Peg(0.50107, 1.16477),
    new Peg(0.50107, 1.12019),
    new Peg(0.50107, 0.02559),
    new Peg(0.50107, 0.22701),
    new Peg(0.50107, 1.29354),
    new Peg(0.5019, 0.10071),
    new Peg(0.5019, 0.52914),
    new Peg(0.5019, 1.20687),
    new Peg(0.5019, 1.33399),
    new Peg(0.5019, 0.18491),
    new Peg(0.5019, 1.37444),
    new Peg(0.50272, 0.35496),
    new Peg(0.50272, 0.14281),
    new Peg(0.50355, 0.48374),
    new Peg(0.50355, 0.44246),
    new Peg(0.50355, 0.40036),
    new Peg(0.50438, 0.26828),
    new Peg(0.50438, 0.31121),
    new Peg(0.52088, 0.99719),
    new Peg(0.52171, 0.84035),
    new Peg(0.52254, 0.7999),
    new Peg(0.52419, 0.64471),
    new Peg(0.52419, 0.91795),
    new Peg(0.52419, 0.60426),
    new Peg(0.52501, 0.56298),
    new Peg(0.52666, 0.06109),
    new Peg(0.52831, 0.72148),
    new Peg(0.53987, 1.07892),
    new Peg(0.5407, 0.7611),
    new Peg(0.5407, 1.24567),
    new Peg(0.54152, 1.16147),
    new Peg(0.54152, 0.17996),
    new Peg(0.54152, 1.11937),
    new Peg(0.54152, 1.03764),
    new Peg(0.54152, 1.37114),
    new Peg(0.54235, 0.43008),
    new Peg(0.54235, 1.28612),
    new Peg(0.54235, 0.51428),
    new Peg(0.54235, 0.38798),
    new Peg(0.54317, 0.22041),
    new Peg(0.54317, 1.20357),
    new Peg(0.54317, 0.95427),
    new Peg(0.54317, 0.09823),
    new Peg(0.54317, 1.32987),
    new Peg(0.54317, 0.47053),
    new Peg(0.544, 0.87502),
    new Peg(0.544, 0.34423),
    new Peg(0.544, 0.13951),
    new Peg(0.54482, 0.6802),
    new Peg(0.54648, 0.30296),
    new Peg(0.5473, 0.26251),
    new Peg(0.56133, 0.99141),
    new Peg(0.56381, 0.5506),
    new Peg(0.56381, 0.64058),
    new Peg(0.56464, 0.91052),
    new Peg(0.56464, 0.83375),
    new Peg(0.56629, 0.7933),
    new Peg(0.56876, 0.59435),
    new Peg(0.56959, 0.7157),
    new Peg(0.57867, 0.41192),
    new Peg(0.57949, 1.07727),
    new Peg(0.57949, 0.36652),
    new Peg(0.58115, 1.03434),
    new Peg(0.58115, 1.24567),
    new Peg(0.58115, 0.11474),
    new Peg(0.58197, 0.7545),
    new Peg(0.58197, 0.20142),
    new Peg(0.5828, 1.28529),
    new Peg(0.5828, 1.32739),
    new Peg(0.5828, 0.15767),
    new Peg(0.5828, 0.32442),
    new Peg(0.5828, 0.95014),
    new Peg(0.58362, 1.16064),
    new Peg(0.58362, 1.20439),
    new Peg(0.58362, 1.36784),
    new Peg(0.58527, 1.11772),
    new Peg(0.58527, 0.24352),
    new Peg(0.58527, 0.49612),
    new Peg(0.5861, 0.86924),
    new Peg(0.5861, 0.45485),
    new Peg(0.5894, 0.67278),
    new Peg(0.59105, 0.28479),
    new Peg(0.60013, 0.07925),
    new Peg(0.60096, 0.53492),
    new Peg(0.60343, 0.98976),
    new Peg(0.60509, 0.82632),
    new Peg(0.60509, 0.90721),
    new Peg(0.60756, 0.5828),
    new Peg(0.60756, 0.78587),
    new Peg(0.61417, 0.70414),
    new Peg(0.61499, 0.38633),
    new Peg(0.61747, 0.18078),
    new Peg(0.61994, 1.07396),
    new Peg(0.61994, 0.42595),
    new Peg(0.62077, 0.74377),
    new Peg(0.62242, 1.03186),
    new Peg(0.62325, 1.32079),
    new Peg(0.62325, 1.23906),
    new Peg(0.62407, 1.36123),
    new Peg(0.62407, 1.27951),
    new Peg(0.62407, 0.34671),
    new Peg(0.6249, 1.11441),
    new Peg(0.6249, 0.46888),
    new Peg(0.62572, 0.94271),
    new Peg(0.62572, 1.15651),
    new Peg(0.62655, 1.19861),
    new Peg(0.62737, 0.86264),
    new Peg(0.62985, 0.05201),
    new Peg(0.63068, 0.21958),
    new Peg(0.63315, 0.30708),
    new Peg(0.63398, 0.51015),
    new Peg(0.63728, 0.26251),
    new Peg(0.63893, 0.55556),
    new Peg(0.64388, 0.81806),
    new Peg(0.64719, 0.77431),
    new Peg(0.64884, 0.98233),
    new Peg(0.64966, 0.89979),
    new Peg(0.65296, 0.719),
    new Peg(0.65627, 0.38055),
    new Peg(0.65792, 1.06736),
    new Peg(0.66039, 0.42926),
    new Peg(0.66122, 1.02113),
    new Peg(0.66369, 0.47961),
    new Peg(0.66369, 1.31336),
    new Peg(0.66535, 1.35463),
    new Peg(0.66535, 1.22751),
    new Peg(0.66535, 1.27043),
    new Peg(0.667, 0.85521),
    new Peg(0.66782, 1.10863),
    new Peg(0.66782, 1.18788),
    new Peg(0.66947, 0.93446),
    new Peg(0.66947, 0.52997),
    new Peg(0.6703, 1.14826),
    new Peg(0.67608, 0.57124),
    new Peg(0.6802, 0.75037),
    new Peg(0.68103, 0.80155),
    new Peg(0.68846, 0.97491),
    new Peg(0.69094, 0.8874),
    new Peg(0.69589, 1.0558),
    new Peg(0.69919, 0.50025),
    new Peg(0.70002, 0.60426),
    new Peg(0.70002, 0.38963),
    new Peg(0.70167, 1.0137),
    new Peg(0.70332, 0.43173),
    new Peg(0.70414, 1.30345),
    new Peg(0.70497, 0.83622),
    new Peg(0.70579, 1.34307),
    new Peg(0.70579, 1.26218),
    new Peg(0.70745, 1.22008),
    new Peg(0.70745, 1.17963),
    new Peg(0.70827, 0.92372),
    new Peg(0.70827, 1.09625),
    new Peg(0.70992, 0.54152),
    new Peg(0.71075, 1.13918),
    new Peg(0.71405, 0.77679),
    new Peg(0.71488, 0.32689),
    new Peg(0.72561, 0.96087),
    new Peg(0.72726, 0.87089),
    new Peg(0.73056, 0.57619),
    new Peg(0.73304, 1.04177),
    new Peg(0.73551, 0.62655),
    new Peg(0.73551, 0.36157),
    new Peg(0.73881, 0.81311),
    new Peg(0.74047, 0.40532),
    new Peg(0.74212, 0.99884),
    new Peg(0.74377, 1.29189),
    new Peg(0.74542, 1.25062),
    new Peg(0.74624, 1.16724),
    new Peg(0.74624, 1.08222),
    new Peg(0.74789, 0.90721),
    new Peg(0.74872, 1.33234),
    new Peg(0.74955, 1.12432),
    new Peg(0.75037, 1.20852),
    new Peg(0.7611, 0.84613),
    new Peg(0.7644, 0.94436),
    new Peg(0.76771, 0.59766),
    new Peg(0.77349, 0.64223),
    new Peg(0.77349, 1.03021),
    new Peg(0.78091, 0.88328),
    new Peg(0.78257, 0.98233),
    new Peg(0.78339, 1.28034),
    new Peg(0.78422, 1.15321),
    new Peg(0.78504, 1.23659),
    new Peg(0.78587, 1.07231),
    new Peg(0.78752, 1.32161),
    new Peg(0.79082, 1.19366),
    new Peg(0.7933, 1.11276),
    new Peg(0.79742, 0.92125),
    new Peg(0.80816, 0.5861),
    new Peg(0.81063, 1.01453),
    new Peg(0.81228, 0.62737),
    new Peg(0.81806, 0.95674),
    new Peg(0.82054, 1.263),
    new Peg(0.82219, 1.22255),
    new Peg(0.82301, 1.14248),
    new Peg(0.82384, 1.05415),
    new Peg(0.82797, 1.30675),
    new Peg(0.8321, 1.18128),
    new Peg(0.83457, 1.09378),
    new Peg(0.84283, 0.98811),
    new Peg(0.84365, 0.56546),
    new Peg(0.8486, 0.60756),
    new Peg(0.85769, 1.02609),
    new Peg(0.85934, 1.2531),
    new Peg(0.85934, 1.12597),
    new Peg(0.86099, 1.211),
    new Peg(0.87007, 1.16559),
    new Peg(0.87089, 1.29354),
    new Peg(0.87089, 1.06736),
    new Peg(0.87832, 0.544),
    new Peg(0.88328, 0.5861),
    new Peg(0.88493, 0.5019),
    new Peg(0.89318, 1.10203),
    new Peg(0.89731, 1.23659),
    new Peg(0.89896, 1.19696),
    new Peg(0.90474, 1.14248),
    new Peg(0.90804, 1.27786),
    new Peg(0.90969, 0.61664),
    new Peg(0.91795, 0.52749),
    new Peg(0.9229, 0.57372),
    new Peg(0.93528, 1.1689),
    new Peg(0.93776, 1.24567),
    new Peg(0.95014, 1.20687),
    new Peg(0.95097, 0.61417),
    new Peg(0.9584, 0.53987),
    new Peg(0.9716, 0.57867),
  ]);
}
