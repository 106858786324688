import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';
import { normalizedPegboard } from './pegboard_normalizer';

export function createDolphin(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadSize: number
): Pegboard {
  return normalizedPegboard(manufacturer, manufacturerId, beadSize, [
    new Peg(0.02128, 0.39362),
    new Peg(0.04846, 0.41371),
    new Peg(0.05024, 0.38239),
    new Peg(0.07506, 0.36407),
    new Peg(0.08097, 0.42612),
    new Peg(0.08215, 0.3948),
    new Peg(0.10106, 0.3487),
    new Peg(0.11052, 0.41253),
    new Peg(0.11111, 0.37825),
    new Peg(0.12825, 0.33511),
    new Peg(0.13652, 0.39716),
    new Peg(0.14007, 0.36407),
    new Peg(0.15721, 0.32624),
    new Peg(0.16726, 0.3948),
    new Peg(0.17139, 0.36466),
    new Peg(0.18558, 0.33747),
    new Peg(0.19799, 0.39125),
    new Peg(0.19622, 0.42258),
    new Peg(0.20213, 0.31206),
    new Peg(0.20508, 0.3617),
    new Peg(0.20686, 0.45154),
    new Peg(0.22222, 0.33629),
    new Peg(0.22636, 0.4078),
    new Peg(0.22695, 0.29196),
    new Peg(0.22754, 0.47518),
    new Peg(0.23463, 0.3747),
    new Peg(0.23641, 0.44031),
    new Peg(0.24645, 0.31678),
    new Peg(0.24941, 0.27128),
    new Peg(0.25296, 0.34929),
    new Peg(0.25532, 0.48877),
    new Peg(0.25709, 0.39598),
    new Peg(0.26418, 0.45686),
    new Peg(0.26478, 0.42553),
    new Peg(0.26891, 0.2961),
    new Peg(0.27364, 0.25059),
    new Peg(0.27955, 0.32742),
    new Peg(0.28664, 0.48582),
    new Peg(0.29255, 0.276),
    new Peg(0.29846, 0.2305),
    new Peg(0.3091, 0.30615),
    new Peg(0.32033, 0.26241),
    new Peg(0.3227, 0.20863),
    new Peg(0.33688, 0.23582),
    new Peg(0.33865, 0.29374),
    new Peg(0.34752, 0.18913),
    new Peg(0.35165, 0.26359),
    new Peg(0.36229, 0.2169),
    new Peg(0.36998, 0.28842),
    new Peg(0.37352, 0.16844),
    new Peg(0.37884, 0.24764),
    new Peg(0.38593, 0.1974),
    new Peg(0.39835, 0.14953),
    new Peg(0.4013, 0.28251),
    new Peg(0.40071, 0.224),
    new Peg(0.41017, 0.25296),
    new Peg(0.41312, 0.18203),
    new Peg(0.42553, 0.13475),
    new Peg(0.42908, 0.20863),
    new Peg(0.43322, 0.27364),
    new Peg(0.43972, 0.16253),
    new Peg(0.44149, 0.24054),
    new Peg(0.45272, 0.11939),
    new Peg(0.45449, 0.19031),
    new Peg(0.46336, 0.21927),
    new Peg(0.46572, 0.26655),
    new Peg(0.46868, 0.14835),
    new Peg(0.48286, 0.17612),
    new Peg(0.48522, 0.10816),
    new Peg(0.48641, 0.23936),
    new Peg(0.49527, 0.20745),
    new Peg(0.49764, 0.26832),
    new Peg(0.49941, 0.13534),
    new Peg(0.513, 0.16312),
    new Peg(0.51537, 0.10225),
    new Peg(0.51714, 0.23522),
    new Peg(0.52719, 0.19208),
    new Peg(0.52896, 0.2695),
    new Peg(0.5331, 0.13002),
    new Peg(0.53369, 0.34634),
    new Peg(0.54433, 0.2169),
    new Peg(0.5461, 0.10106),
    new Peg(0.54433, 0.15898),
    new Peg(0.55024, 0.24704),
    new Peg(0.55142, 0.02423),
    new Peg(0.55792, 0.36643),
    new Peg(0.5591, 0.27955),
    new Peg(0.56028, 0.18913),
    new Peg(0.56087, 0.33156),
    new Peg(0.56383, 0.1318),
    new Peg(0.57388, 0.22459),
    new Peg(0.57742, 0.10225),
    new Peg(0.57801, 0.16312),
    new Peg(0.58038, 0.2565),
    new Peg(0.58274, 0.02187),
    new Peg(0.58747, 0.38357),
    new Peg(0.58747, 0.35284),
    new Peg(0.58865, 0.31915),
    new Peg(0.58924, 0.28783),
    new Peg(0.58983, 0.19858),
    new Peg(0.59515, 0.13771),
    new Peg(0.60284, 0.07979),
    new Peg(0.60343, 0.23404),
    new Peg(0.6052, 0.04905),
    new Peg(0.60934, 0.17435),
    new Peg(0.60757, 0.10993),
    new Peg(0.61288, 0.01891),
    new Peg(0.61466, 0.27009),
    new Peg(0.61584, 0.34043),
    new Peg(0.61643, 0.30615),
    new Peg(0.61702, 0.37707),
    new Peg(0.62293, 0.20863),
    new Peg(0.6247, 0.14539),
    new Peg(0.63357, 0.08392),
    new Peg(0.63475, 0.24054),
    new Peg(0.6383, 0.11525),
    new Peg(0.63771, 0.05319),
    new Peg(0.64184, 0.18262),
    new Peg(0.64362, 0.02305),
    new Peg(0.64598, 0.27305),
    new Peg(0.64835, 0.36879),
    new Peg(0.64953, 0.30319),
    new Peg(0.64953, 0.33452),
    new Peg(0.65366, 0.15426),
    new Peg(0.65721, 0.2169),
    new Peg(0.66312, 0.09397),
    new Peg(0.66785, 0.1253),
    new Peg(0.66785, 0.24882),
    new Peg(0.66785, 0.06383),
    new Peg(0.67317, 0.0331),
    new Peg(0.67376, 0.18735),
    new Peg(0.67553, 0.28546),
    new Peg(0.67671, 0.35284),
    new Peg(0.6773, 0.31856),
    new Peg(0.6844, 0.15662),
    new Peg(0.68735, 0.22518),
    new Peg(0.69385, 0.09693),
    new Peg(0.6974, 0.05201),
    new Peg(0.69622, 0.263),
    new Peg(0.69858, 0.12766),
    new Peg(0.70331, 0.29965),
    new Peg(0.70331, 0.3357),
    new Peg(0.70331, 0.37352),
    new Peg(0.7039, 0.19858),
    new Peg(0.71631, 0.07624),
    new Peg(0.71277, 0.16844),
    new Peg(0.71572, 0.23877),
    new Peg(0.7234, 0.10697),
    new Peg(0.724, 0.27482),
    new Peg(0.72754, 0.1383),
    new Peg(0.72991, 0.31619),
    new Peg(0.72991, 0.35697),
    new Peg(0.7305, 0.39716),
    new Peg(0.73227, 0.2104),
    new Peg(0.74232, 0.17908),
    new Peg(0.74468, 0.25177),
    new Peg(0.75, 0.29314),
    new Peg(0.75296, 0.3357),
    new Peg(0.75591, 0.14953),
    new Peg(0.7565, 0.38121),
    new Peg(0.75887, 0.41548),
    new Peg(0.76123, 0.22281),
    new Peg(0.77069, 0.19267),
    new Peg(0.77069, 0.26891),
    new Peg(0.7766, 0.31324),
    new Peg(0.7766, 0.35579),
    new Peg(0.78369, 0.1643),
    new Peg(0.78664, 0.24113),
    new Peg(0.78428, 0.39657),
    new Peg(0.78901, 0.42849),
    new Peg(0.79728, 0.28901),
    new Peg(0.79965, 0.33511),
    new Peg(0.79787, 0.20922),
    new Peg(0.80319, 0.37293),
    new Peg(0.81206, 0.18026),
    new Peg(0.81206, 0.26064),
    new Peg(0.81856, 0.40071),
    new Peg(0.81974, 0.31206),
    new Peg(0.82092, 0.2305),
    new Peg(0.82033, 0.43322),
    new Peg(0.82624, 0.35284),
    new Peg(0.83333, 0.28251),
    new Peg(0.83747, 0.20154),
    new Peg(0.84161, 0.38061),
    new Peg(0.8487, 0.25296),
    new Peg(0.84634, 0.32979),
    new Peg(0.84988, 0.44149),
    new Peg(0.85343, 0.40898),
    new Peg(0.85875, 0.30083),
    new Peg(0.86111, 0.35697),
    new Peg(0.86288, 0.2234),
    new Peg(0.87352, 0.27364),
    new Peg(0.87707, 0.32861),
    new Peg(0.88002, 0.45331),
    new Peg(0.87766, 0.38948),
    new Peg(0.88357, 0.42258),
    new Peg(0.88771, 0.24586),
    new Peg(0.89243, 0.36288),
    new Peg(0.89539, 0.30319),
    new Peg(0.90721, 0.40248),
    new Peg(0.90662, 0.46868),
    new Peg(0.9078, 0.27187),
    new Peg(0.90957, 0.33747),
    new Peg(0.91371, 0.43913),
    new Peg(0.9208, 0.3747),
    new Peg(0.92612, 0.29728),
    new Peg(0.93262, 0.48522),
    new Peg(0.93499, 0.41726),
    new Peg(0.94031, 0.32565),
    new Peg(0.94326, 0.45567),
    new Peg(0.9474, 0.35638),
    new Peg(0.94799, 0.38948),
    new Peg(0.96277, 0.43085),
    new Peg(0.96395, 0.48522),
    new Peg(0.97754, 0.45804),
  ]);
}
