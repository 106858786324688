import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';
import { normalizedPegboard } from './pegboard_normalizer';

export function createNumbers(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadSize: number
): Pegboard {
  return normalizedPegboard(manufacturer, manufacturerId, beadSize, [
    new Peg(0.07563, 0.52596),
    new Peg(0.07807, 0.49484),
    new Peg(0.07929, 0.46373),
    new Peg(0.08234, 0.43322),
    new Peg(0.08539, 0.4021),
    new Peg(0.08783, 0.37037),
    new Peg(0.09088, 0.34047),
    new Peg(0.10614, 0.52596),
    new Peg(0.11468, 0.432),
    new Peg(0.12383, 0.33986),
    new Peg(0.13665, 0.52657),
    new Peg(0.14519, 0.43322),
    new Peg(0.15617, 0.34047),
    new Peg(0.16654, 0.52596),
    new Peg(0.16715, 0.24895),
    new Peg(0.17021, 0.49484),
    new Peg(0.17021, 0.21783),
    new Peg(0.17265, 0.18732),
    new Peg(0.17387, 0.46434),
    new Peg(0.17448, 0.1562),
    new Peg(0.17692, 0.43261),
    new Peg(0.17753, 0.1263),
    new Peg(0.1818, 0.0958),
    new Peg(0.18363, 0.06529),
    new Peg(0.18607, 0.34108),
    new Peg(0.2599, 0.24895),
    new Peg(0.26234, 0.21783),
    new Peg(0.26478, 0.18671),
    new Peg(0.26844, 0.1562),
    new Peg(0.27759, 0.06468),
    new Peg(0.27759, 0.34169),
    new Peg(0.29285, 0.24956),
    new Peg(0.29895, 0.15681),
    new Peg(0.3081, 0.34108),
    new Peg(0.3081, 0.0659),
    new Peg(0.32336, 0.24895),
    new Peg(0.32885, 0.15803),
    new Peg(0.33861, 0.0659),
    new Peg(0.33861, 0.34047),
    new Peg(0.34959, 0.52474),
    new Peg(0.35203, 0.49362),
    new Peg(0.35387, 0.24956),
    new Peg(0.35448, 0.46312),
    new Peg(0.35875, 0.43322),
    new Peg(0.36058, 0.15803),
    new Peg(0.36241, 0.12752),
    new Peg(0.36241, 0.40271),
    new Peg(0.36607, 0.37281),
    new Peg(0.36607, 0.09519),
    new Peg(0.36912, 0.34169),
    new Peg(0.36973, 0.0659),
    new Peg(0.44173, 0.52291),
    new Peg(0.44417, 0.49301),
    new Peg(0.44478, 0.24956),
    new Peg(0.44722, 0.46251),
    new Peg(0.44966, 0.432),
    new Peg(0.4521, 0.15742),
    new Peg(0.4521, 0.4021),
    new Peg(0.45515, 0.3722),
    new Peg(0.4582, 0.33986),
    new Peg(0.46064, 0.06712),
    new Peg(0.47468, 0.52352),
    new Peg(0.47529, 0.25017),
    new Peg(0.48017, 0.43139),
    new Peg(0.48322, 0.15864),
    new Peg(0.49176, 0.34169),
    new Peg(0.49176, 0.06712),
    new Peg(0.50458, 0.52291),
    new Peg(0.5058, 0.25017),
    new Peg(0.5119, 0.432),
    new Peg(0.51312, 0.15864),
    new Peg(0.52227, 0.34291),
    new Peg(0.52288, 0.06651),
    new Peg(0.53569, 0.52474),
    new Peg(0.53569, 0.25078),
    new Peg(0.53753, 0.22088),
    new Peg(0.53875, 0.49362),
    new Peg(0.54119, 0.19037),
    new Peg(0.5418, 0.46312),
    new Peg(0.54302, 0.15925),
    new Peg(0.54424, 0.43322),
    new Peg(0.54546, 0.4021),
    new Peg(0.54668, 0.12691),
    new Peg(0.5479, 0.3722),
    new Peg(0.54973, 0.09702),
    new Peg(0.55278, 0.34291),
    new Peg(0.55339, 0.06651),
    new Peg(0.62722, 0.52413),
    new Peg(0.63454, 0.15864),
    new Peg(0.63454, 0.43322),
    new Peg(0.63637, 0.12813),
    new Peg(0.63881, 0.40332),
    new Peg(0.64003, 0.09763),
    new Peg(0.64186, 0.3722),
    new Peg(0.64308, 0.06651),
    new Peg(0.64491, 0.3423),
    new Peg(0.65773, 0.52535),
    new Peg(0.66627, 0.15864),
    new Peg(0.66749, 0.43383),
    new Peg(0.67542, 0.3423),
    new Peg(0.68824, 0.52718),
    new Peg(0.69739, 0.15986),
    new Peg(0.69922, 0.43383),
    new Peg(0.70593, 0.34108),
    new Peg(0.71813, 0.25078),
    new Peg(0.71935, 0.52718),
    new Peg(0.7218, 0.22027),
    new Peg(0.7218, 0.49606),
    new Peg(0.72424, 0.46495),
    new Peg(0.72607, 0.18976),
    new Peg(0.72729, 0.15986),
    new Peg(0.72912, 0.12813),
    new Peg(0.72912, 0.43505),
    new Peg(0.73095, 0.40332),
    new Peg(0.73278, 0.37342),
    new Peg(0.73339, 0.09702),
    new Peg(0.73583, 0.06712),
    new Peg(0.73644, 0.3423),
    new Peg(0.81088, 0.25139),
    new Peg(0.81088, 0.52718),
    new Peg(0.81515, 0.49606),
    new Peg(0.81759, 0.46617),
    new Peg(0.82003, 0.15986),
    new Peg(0.82003, 0.43505),
    new Peg(0.82125, 0.12936),
    new Peg(0.82247, 0.40454),
    new Peg(0.8243, 0.37403),
    new Peg(0.8243, 0.09885),
    new Peg(0.82552, 0.06712),
    new Peg(0.82796, 0.34291),
    new Peg(0.84078, 0.25139),
    new Peg(0.84139, 0.52657),
    new Peg(0.84993, 0.15986),
    new Peg(0.85847, 0.06773),
    new Peg(0.85908, 0.34291),
    new Peg(0.87129, 0.52779),
    new Peg(0.87312, 0.25139),
    new Peg(0.88166, 0.15986),
    new Peg(0.88959, 0.06773),
    new Peg(0.8902, 0.34352),
    new Peg(0.90118, 0.52779),
    new Peg(0.90484, 0.25261),
    new Peg(0.90484, 0.49667),
    new Peg(0.90668, 0.2221),
    new Peg(0.9079, 0.19098),
    new Peg(0.9079, 0.46617),
    new Peg(0.91095, 0.43566),
    new Peg(0.91217, 0.15925),
    new Peg(0.914, 0.40515),
    new Peg(0.91644, 0.37464),
    new Peg(0.9201, 0.34352),
    new Peg(0.92071, 0.06895),
  ]);
}
