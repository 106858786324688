import { User } from 'firebase/auth';
import { Item } from '../common/model/item';
import Tag from '../common/tag/tag';
import { Bead } from './bead';
import { Peg, Pegboard } from './pegboard';

export class BeadPattern extends Item<BeadPattern> {
  constructor(
    id: string | undefined,
    uid: string | undefined,
    isPublic: boolean,
    approved: boolean,
    likers: Set<String>,
    tags: Set<Tag>,
    readonly pegboard: Pegboard,
    readonly beads: Array<Bead | undefined>
  ) {
    super('bead_pattern', id, uid, isPublic, approved, likers, tags);
    if (pegboard.pegs.length !== beads.length) {
      throw new Error(
        `number of peg [${pegboard.pegs.length}] does not match number of beads [${beads.length}]`
      );
    }
  }

  static empty(pegboard: Pegboard, uid: string | undefined): BeadPattern {
    return new BeadPattern(
      undefined,
      uid,
      true,
      false,
      new Set([]),
      new Set([]),
      pegboard,
      Array(pegboard.pegs.length).fill(undefined)
    );
  }

  override get isComplete() {
    return true;
  }

  override setPublic(isPublic: boolean) {
    if (this.public === isPublic) {
      return this;
    }
    return new BeadPattern(
      this.id,
      this.uid,
      isPublic,
      this.approved,
      this.likers,
      this.tags,
      this.pegboard,
      this.beads
    );
  }

  override setApproved(approved: boolean) {
    if (this.approved === approved) {
      return this;
    }
    return new BeadPattern(
      this.id,
      this.uid,
      this.public,
      approved,
      this.likers,
      this.tags,
      this.pegboard,
      this.beads
    );
  }

  override async addLiker(user: User) {
    if (this.likers.has(user.uid)) {
      return this;
    }

    return new BeadPattern(
      this.id,
      this.uid,
      this.public,
      this.approved,
      new Set([...Array.from(this.likers), user.uid]),
      this.tags,
      this.pegboard,
      this.beads
    );
  }

  override async removeLiker(user: User) {
    if (!this.likers.has(user.uid)) {
      return this;
    }

    return new BeadPattern(
      this.id,
      this.uid,
      this.public,
      this.approved,
      new Set(Array.from(this.likers).filter((l) => l !== user.uid)),
      this.tags,
      this.pegboard,
      this.beads
    );
  }

  override copyForCurrentUser(uid: string) {
    return new BeadPattern(
      undefined,
      uid,
      true,
      false,
      new Set([]),
      this.tags,
      this.pegboard,
      this.beads
    );
  }

  override setTags(tags: Set<Tag>): BeadPattern {
    if (this.tags === tags) {
      return this;
    }
    return new BeadPattern(
      this.id,
      this.uid,
      this.public,
      this.approved,
      this.likers,
      tags,
      this.pegboard,
      this.beads
    );
  }

  private _setBead(ix: number, bead: Bead | undefined): BeadPattern {
    if (bead && bead.diameter !== this.pegboard.beadDiameter) {
      throw new Error(
        `bead diameter [${bead.diameter}] does not match pegboard bead diameter [${this.pegboard.beadDiameter}]`
      );
    }
    if (this.beads[ix]?.id === bead?.id) {
      return this;
    }
    const beads = this.beads.slice();
    beads[ix] = bead;
    return new BeadPattern(
      this.id,
      this.uid,
      this.public,
      this.approved,
      this.likers,
      this.tags,
      this.pegboard,
      beads
    );
  }

  setBead(peg: Peg, bead: Bead | undefined): BeadPattern {
    return this._setBead(this.pegboard.pegs.indexOf(peg), bead);
  }

  getBead(peg: Peg): Bead | undefined {
    return this.beads[this.pegboard.pegs.indexOf(peg)];
  }

  fill(peg: Peg, bead: Bead): BeadPattern {
    const beads = this.beads.slice();
    const pegIx = this.pegboard.pegs.indexOf(peg);
    const beadToReplace = beads[pegIx];
    const queue = new Set([peg]);
    const checkedPegs = new Set<Peg>();
    while (queue.size > 0) {
      const peg = queue.values().next().value;
      queue.delete(peg);
      const pix = this.pegboard.pegs.indexOf(peg);
      if (beads[pix]?.id === beadToReplace?.id) {
        beads[pix] = bead;
        for (let neighbour of this.pegboard.getAdjacentPegs(
          peg,
          this.pegboard.beadDiameter * 1.3
        )) {
          if (checkedPegs.has(neighbour)) {
            continue;
          }
          queue.add(neighbour);
        }
      }
      checkedPegs.add(peg);
    }
    return new BeadPattern(
      this.id,
      this.uid,
      this.public,
      this.approved,
      this.likers,
      this.tags,
      this.pegboard,
      beads
    );
  }

  rotatePegboardLeft(): BeadPattern {
    if (!this.pegboard.rotationStep) {
      throw new Error(`pegboard ${this.pegboard.id} does not support rotation`);
    }

    return this.rotatePegboard(-this.pegboard.rotationStep!);
  }

  rotatePegboardRight(): BeadPattern {
    if (!this.pegboard.rotationStep) {
      throw new Error(`pegboard ${this.pegboard.id} does not support rotation`);
    }
    return this.rotatePegboard(this.pegboard.rotationStep!);
  }

  private rotatePegboard(angle: number): BeadPattern {
    if (angle % 360 === 0) {
      return this;
    }
    return new BeadPattern(
      this.id,
      this.uid,
      this.public,
      this.approved,
      this.likers,
      this.tags,
      this.pegboard.rotate(angle),
      this.beads
    );
  }
}
