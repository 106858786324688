const editBufferMaxSize = 30;

export default class EditBuffer<T> {
  private constructor(
    private readonly buffer: Array<T>,
    private readonly ix: number,
    // id is just for debugging purposes
    readonly id: number
  ) {}

  static single<T>(item: T): EditBuffer<T> {
    return new EditBuffer<T>([item], 0, 0);
  }

  get current(): T {
    return this.buffer[this.ix];
  }

  maybeAdd(item: T): EditBuffer<T> {
    if (item === this.buffer[this.ix]) {
      return this;
    }
    const newBuffer = [...this.buffer.slice(0, this.ix + 1), item];
    let newIx = this.ix + 1;
    if (newBuffer.length > editBufferMaxSize) {
      newBuffer.shift();
      newIx--;
    }
    return new EditBuffer<T>(newBuffer, newIx, this.id + 1);
  }

  get canRedo(): boolean {
    return this.ix < this.buffer.length - 1;
  }

  maybeRedo(): EditBuffer<T> {
    if (this.ix < this.buffer.length - 1) {
      return new EditBuffer(this.buffer, this.ix + 1, this.id + 1);
    }
    return this;
  }

  get canUndo(): boolean {
    return this.ix > 0;
  }

  maybeUndo(): EditBuffer<T> {
    if (this.ix > 0) {
      return new EditBuffer(this.buffer, this.ix - 1, this.id + 1);
    }
    return this;
  }
}
