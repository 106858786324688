import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';
import { normalizedPegboard } from './pegboard_normalizer';

export function createFrog(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadSize: number
): Pegboard {
  return normalizedPegboard(manufacturer, manufacturerId, beadSize, [
    new Peg(0.02716, 0.31273),
    new Peg(0.03379, 0.35116),
    new Peg(0.05433, 0.28159),
    new Peg(0.07089, 0.3664),
    new Peg(0.07354, 0.322),
    new Peg(0.07686, 0.86398),
    new Peg(0.08017, 0.93951),
    new Peg(0.09475, 0.28225),
    new Peg(0.10998, 0.35248),
    new Peg(0.11794, 0.85271),
    new Peg(0.1186, 0.92427),
    new Peg(0.12059, 0.31339),
    new Peg(0.12721, 0.2425),
    new Peg(0.1398, 0.5857),
    new Peg(0.14643, 0.28026),
    new Peg(0.14974, 0.35712),
    new Peg(0.15106, 0.62744),
    new Peg(0.1557, 0.83946),
    new Peg(0.15438, 0.8812),
    new Peg(0.15968, 0.54926),
    new Peg(0.16034, 0.92096),
    new Peg(0.16299, 0.31869),
    new Peg(0.1716, 0.66388),
    new Peg(0.1716, 1.0051),
    new Peg(0.17359, 0.38892),
    new Peg(0.18883, 0.5857),
    new Peg(0.19148, 0.62744),
    new Peg(0.19413, 0.89048),
    new Peg(0.19545, 0.8388),
    new Peg(0.19612, 0.34387),
    new Peg(0.19943, 0.42271),
    new Peg(0.20142, 0.54396),
    new Peg(0.20076, 0.93553),
    new Peg(0.20274, 0.68906),
    new Peg(0.20738, 0.98456),
    new Peg(0.21069, 0.21003),
    new Peg(0.21401, 0.25111),
    new Peg(0.21401, 0.38561),
    new Peg(0.22792, 0.65726),
    new Peg(0.23123, 0.61353),
    new Peg(0.2319, 0.17492),
    new Peg(0.23322, 0.56848),
    new Peg(0.23521, 0.35116),
    new Peg(0.23587, 0.88054),
    new Peg(0.23653, 0.83946),
    new Peg(0.24117, 0.92162),
    new Peg(0.23786, 0.71755),
    new Peg(0.23786, 0.43729),
    new Peg(0.25111, 0.14046),
    new Peg(0.25045, 0.96469),
    new Peg(0.25177, 0.26502),
    new Peg(0.25177, 0.3982),
    new Peg(0.25509, 0.22262),
    new Peg(0.25906, 0.68442),
    new Peg(0.26701, 0.58901),
    new Peg(0.269, 0.63341),
    new Peg(0.27165, 0.18088),
    new Peg(0.27364, 0.36374),
    new Peg(0.27231, 0.10733),
    new Peg(0.2743, 0.80435),
    new Peg(0.27165, 0.5486),
    new Peg(0.27496, 0.84741),
    new Peg(0.27695, 0.89114),
    new Peg(0.27827, 0.44656),
    new Peg(0.27894, 0.76194),
    new Peg(0.2796, 0.50951),
    new Peg(0.28092, 0.93222),
    new Peg(0.28358, 0.71821),
    new Peg(0.29086, 0.06957),
    new Peg(0.29153, 0.4088),
    new Peg(0.29418, 0.26039),
    new Peg(0.2955, 0.14245),
    new Peg(0.29881, 0.22063),
    new Peg(0.30014, 0.67714),
    new Peg(0.30345, 0.47638),
    new Peg(0.3114, 0.55324),
    new Peg(0.31273, 0.5963),
    new Peg(0.31273, 0.63871),
    new Peg(0.31273, 0.37501),
    new Peg(0.31472, 0.18154),
    new Peg(0.31472, 0.03843),
    new Peg(0.31538, 0.90837),
    new Peg(0.31538, 0.82754),
    new Peg(0.31604, 0.86861),
    new Peg(0.3167, 0.10733),
    new Peg(0.31869, 0.78646),
    new Peg(0.31869, 0.7467),
    new Peg(0.32068, 0.51415),
    new Peg(0.32267, 0.43331),
    new Peg(0.32797, 0.70828),
    new Peg(0.33459, 0.27165),
    new Peg(0.33592, 0.14378),
    new Peg(0.33658, 0.07156),
    new Peg(0.34321, 0.2319),
    new Peg(0.34321, 0.47505),
    new Peg(0.34586, 0.67316),
    new Peg(0.34851, 0.35778),
    new Peg(0.35314, 0.80965),
    new Peg(0.35182, 0.63341),
    new Peg(0.35049, 0.4035),
    new Peg(0.35314, 0.85139),
    new Peg(0.35381, 0.58968),
    new Peg(0.35447, 0.89048),
    new Peg(0.35646, 0.54992),
    new Peg(0.35712, 0.0265),
    new Peg(0.35712, 0.1822),
    new Peg(0.35844, 0.10667),
    new Peg(0.36109, 0.51017),
    new Peg(0.36242, 0.73412),
    new Peg(0.36573, 0.4406),
    new Peg(0.3664, 0.2955),
    new Peg(0.37832, 0.69701),
    new Peg(0.377, 0.1451),
    new Peg(0.377, 0.07156),
    new Peg(0.37965, 0.21666),
    new Peg(0.38097, 0.25774),
    new Peg(0.38296, 0.47704),
    new Peg(0.38561, 0.38296),
    new Peg(0.38495, 0.34321),
    new Peg(0.38958, 0.61552),
    new Peg(0.39157, 0.90704),
    new Peg(0.39223, 0.82224),
    new Peg(0.39356, 0.86464),
    new Peg(0.39356, 0.57311),
    new Peg(0.39489, 0.6566),
    new Peg(0.3982, 0.10866),
    new Peg(0.39687, 0.53336),
    new Peg(0.39754, 0.18154),
    new Peg(0.39754, 0.03512),
    new Peg(0.39886, 0.75333),
    new Peg(0.40019, 0.42072),
    new Peg(0.4035, 0.30875),
    new Peg(0.4141, 0.7149),
    new Peg(0.41675, 0.14378),
    new Peg(0.41807, 0.4565),
    new Peg(0.41807, 0.07156),
    new Peg(0.4194, 0.49692),
    new Peg(0.42072, 0.36441),
    new Peg(0.42006, 0.27297),
    new Peg(0.41807, 0.23057),
    new Peg(0.43, 0.84012),
    new Peg(0.43, 0.67912),
    new Peg(0.43066, 0.6122),
    new Peg(0.42934, 0.88187),
    new Peg(0.43464, 0.57311),
    new Peg(0.43596, 0.40482),
    new Peg(0.43729, 0.53402),
    new Peg(0.43861, 0.18088),
    new Peg(0.43861, 0.10866),
    new Peg(0.43861, 0.75929),
    new Peg(0.43861, 0.32863),
    new Peg(0.45915, 0.14311),
    new Peg(0.4565, 0.21599),
    new Peg(0.4565, 0.64732),
    new Peg(0.45717, 0.25509),
    new Peg(0.45849, 0.29484),
    new Peg(0.45783, 0.07023),
    new Peg(0.45783, 0.48566),
    new Peg(0.45849, 0.72086),
    new Peg(0.45915, 0.44259),
    new Peg(0.45982, 0.36905),
    new Peg(0.47505, 0.52276),
    new Peg(0.47638, 0.68111),
    new Peg(0.47969, 0.76592),
    new Peg(0.47837, 0.40615),
    new Peg(0.47837, 0.60624),
    new Peg(0.47903, 0.10667),
    new Peg(0.47903, 0.5645),
    new Peg(0.47969, 0.33393),
    new Peg(0.48102, 0.17955),
    new Peg(0.50222, 0.44259),
    new Peg(0.49891, 0.72616),
    new Peg(0.49957, 0.48367),
    new Peg(0.49957, 0.25774),
    new Peg(0.50222, 0.14113),
    new Peg(0.49957, 0.64533),
    new Peg(0.50023, 0.29815),
    new Peg(0.50023, 0.06824),
    new Peg(0.50089, 0.21467),
    new Peg(0.50156, 0.3717),
    new Peg(0.5221, 0.17955),
    new Peg(0.52077, 0.33393),
    new Peg(0.52408, 0.10667),
    new Peg(0.52408, 0.5645),
    new Peg(0.52475, 0.60624),
    new Peg(0.52408, 0.40615),
    new Peg(0.52077, 0.76658),
    new Peg(0.52673, 0.68111),
    new Peg(0.52408, 0.52143),
    new Peg(0.5433, 0.36905),
    new Peg(0.54396, 0.44259),
    new Peg(0.54462, 0.72086),
    new Peg(0.54529, 0.07023),
    new Peg(0.5433, 0.48566),
    new Peg(0.5433, 0.2955),
    new Peg(0.5433, 0.25509),
    new Peg(0.5433, 0.21599),
    new Peg(0.54661, 0.64533),
    new Peg(0.5433, 0.14311),
    new Peg(0.56317, 0.32929),
    new Peg(0.5645, 0.75929),
    new Peg(0.5645, 0.10866),
    new Peg(0.5645, 0.18154),
    new Peg(0.56583, 0.40549),
    new Peg(0.56583, 0.53402),
    new Peg(0.56848, 0.57311),
    new Peg(0.57311, 0.67912),
    new Peg(0.56715, 0.88187),
    new Peg(0.57378, 0.6122),
    new Peg(0.56848, 0.84145),
    new Peg(0.58173, 0.23057),
    new Peg(0.58305, 0.27297),
    new Peg(0.58371, 0.49692),
    new Peg(0.58239, 0.36374),
    new Peg(0.58504, 0.4565),
    new Peg(0.58504, 0.07156),
    new Peg(0.58636, 0.14378),
    new Peg(0.5857, 0.71623),
    new Peg(0.59962, 0.30875),
    new Peg(0.60359, 0.42006),
    new Peg(0.60492, 0.75134),
    new Peg(0.60558, 0.18088),
    new Peg(0.60558, 0.03512),
    new Peg(0.60624, 0.53336),
    new Peg(0.60492, 0.10866),
    new Peg(0.60823, 0.6566),
    new Peg(0.60359, 0.86663),
    new Peg(0.60955, 0.57311),
    new Peg(0.6069, 0.82422),
    new Peg(0.60558, 0.90903),
    new Peg(0.61353, 0.61552),
    new Peg(0.61817, 0.34321),
    new Peg(0.6175, 0.38296),
    new Peg(0.62016, 0.47704),
    new Peg(0.62214, 0.25774),
    new Peg(0.62347, 0.21666),
    new Peg(0.62612, 0.1451),
    new Peg(0.62612, 0.07156),
    new Peg(0.62413, 0.69767),
    new Peg(0.63672, 0.2955),
    new Peg(0.63738, 0.4406),
    new Peg(0.64069, 0.73412),
    new Peg(0.64202, 0.51017),
    new Peg(0.64467, 0.10667),
    new Peg(0.64599, 0.55059),
    new Peg(0.64599, 0.0265),
    new Peg(0.64599, 0.1822),
    new Peg(0.64268, 0.89247),
    new Peg(0.64931, 0.58968),
    new Peg(0.64401, 0.85139),
    new Peg(0.65262, 0.4035),
    new Peg(0.64732, 0.81097),
    new Peg(0.65063, 0.63407),
    new Peg(0.65461, 0.35778),
    new Peg(0.65792, 0.67316),
    new Peg(0.65991, 0.47505),
    new Peg(0.65991, 0.2319),
    new Peg(0.66653, 0.07156),
    new Peg(0.6672, 0.14378),
    new Peg(0.66852, 0.27165),
    new Peg(0.67515, 0.70828),
    new Peg(0.68045, 0.43331),
    new Peg(0.68177, 0.51415),
    new Peg(0.68111, 0.74737),
    new Peg(0.68442, 0.78646),
    new Peg(0.68641, 0.10733),
    new Peg(0.68442, 0.86795),
    new Peg(0.68442, 0.90837),
    new Peg(0.68707, 0.82754),
    new Peg(0.6884, 0.18154),
    new Peg(0.6884, 0.03843),
    new Peg(0.69105, 0.37368),
    new Peg(0.69039, 0.63871),
    new Peg(0.69039, 0.5963),
    new Peg(0.69171, 0.55324),
    new Peg(0.69966, 0.47638),
    new Peg(0.70297, 0.67714),
    new Peg(0.7043, 0.22063),
    new Peg(0.70761, 0.14245),
    new Peg(0.70894, 0.26039),
    new Peg(0.71159, 0.40747),
    new Peg(0.71225, 0.06957),
    new Peg(0.71954, 0.71821),
    new Peg(0.71623, 0.9362),
    new Peg(0.72351, 0.50951),
    new Peg(0.7202, 0.76128),
    new Peg(0.72418, 0.44458),
    new Peg(0.72616, 0.89114),
    new Peg(0.72815, 0.84741),
    new Peg(0.7308, 0.54926),
    new Peg(0.72219, 0.80501),
    new Peg(0.73014, 0.10601),
    new Peg(0.7308, 0.36308),
    new Peg(0.73146, 0.18088),
    new Peg(0.73412, 0.63341),
    new Peg(0.7361, 0.58901),
    new Peg(0.74339, 0.68244),
    new Peg(0.74803, 0.22262),
    new Peg(0.75068, 0.40019),
    new Peg(0.75134, 0.26502),
    new Peg(0.74803, 0.96601),
    new Peg(0.75134, 0.13914),
    new Peg(0.76393, 0.43795),
    new Peg(0.75995, 0.92427),
    new Peg(0.76062, 0.71821),
    new Peg(0.76658, 0.83946),
    new Peg(0.76393, 0.8812),
    new Peg(0.76989, 0.35116),
    new Peg(0.77056, 0.56715),
    new Peg(0.77122, 0.17492),
    new Peg(0.77188, 0.61353),
    new Peg(0.77586, 0.65461),
    new Peg(0.78911, 0.25111),
    new Peg(0.78911, 0.38561),
    new Peg(0.7911, 0.21069),
    new Peg(0.78778, 0.98456),
    new Peg(0.79441, 0.68972),
    new Peg(0.80103, 0.93885),
    new Peg(0.80302, 0.54264),
    new Peg(0.80236, 0.42404),
    new Peg(0.80898, 0.34254),
    new Peg(0.80766, 0.8388),
    new Peg(0.80435, 0.89048),
    new Peg(0.81163, 0.62744),
    new Peg(0.81428, 0.5857),
    new Peg(0.82886, 0.38561),
    new Peg(0.82952, 0.66388),
    new Peg(0.82555, 1.0051),
    new Peg(0.84012, 0.31869),
    new Peg(0.8388, 0.92096),
    new Peg(0.84277, 0.55059),
    new Peg(0.84476, 0.87988),
    new Peg(0.84741, 0.83946),
    new Peg(0.85205, 0.62744),
    new Peg(0.85735, 0.35778),
    new Peg(0.85801, 0.28291),
    new Peg(0.86066, 0.58636),
    new Peg(0.8759, 0.2425),
    new Peg(0.88319, 0.31472),
    new Peg(0.87988, 0.92692),
    new Peg(0.88253, 0.85801),
    new Peg(0.89644, 0.35381),
    new Peg(0.90903, 0.28225),
    new Peg(0.91698, 0.94017),
    new Peg(0.92096, 0.86928),
    new Peg(0.93156, 0.32399),
    new Peg(0.93355, 0.36971),
    new Peg(0.94878, 0.28159),
    new Peg(0.96932, 0.35116),
    new Peg(0.97595, 0.31273),
  ]);
}
