import { Manufacturer } from '../manufacturer';
import { Peg, Pegboard } from '../pegboard';
import { rotateAndTranslateToTheTopLeft } from './pegboard_normalizer';

export function createHeart(
  manufacturer: Manufacturer,
  manufacturerId: string,
  size: number, // The size of the inner square
  numberOfWingLines: number, // Number of lines to add before the half circles
  beadSize: number
): Pegboard {
  // We draw the heart so that the tip of the heart is on the top left
  // and so that the round half circles are to the right and bottom
  // (i.e. we draw it rotated by 135 degrees clockwise from a normal heart)
  const midPointIx = Math.floor(size / 2);
  const pegs: Array<Peg> = [];

  // A sizexsize square
  for (let iy = 1; iy <= size; iy++) {
    for (let ix = 1; ix <= size; ix++) {
      pegs.push(new Peg(ix * beadSize, iy * beadSize));
    }
  }

  function addHalfCircle(centerPeg: Peg, angleOffset: number) {
    for (let r = 1; r <= midPointIx; r++) {
      const numberOfPegInCircle = r * 6;
      for (let angleIx = 0; angleIx < numberOfPegInCircle / 2 + 1; angleIx++) {
        const angle =
          ((2 * Math.PI) / numberOfPegInCircle) * angleIx + angleOffset;
        pegs.push(
          new Peg(
            Math.cos(angle) * r * beadSize + centerPeg.x,
            Math.sin(angle) * r * beadSize + centerPeg.y
          )
        );
      }
    }
  }

  // Add numberOfWingLines lines on the right
  for (let ix = 1; ix <= numberOfWingLines; ix++) {
    for (let iy = 1; iy <= size; iy++) {
      pegs.push(new Peg((size + ix) * beadSize, iy * beadSize));
    }
  }

  // Right half circle
  const centerPegRightHalfCircle = new Peg(
    (size + numberOfWingLines + 1) * beadSize,
    midPointIx * beadSize + beadSize
  );
  pegs.push(centerPegRightHalfCircle);
  addHalfCircle(centerPegRightHalfCircle, -Math.PI / 2);

  // Add numberOfWingLines lines at the bottom
  for (let iy = 1; iy <= numberOfWingLines; iy++) {
    for (let ix = 1; ix <= size; ix++) {
      pegs.push(new Peg(ix * beadSize, (size + iy) * beadSize));
    }
  }

  // Bottom half circle
  const centerPegBottomHalfCircle = new Peg(
    midPointIx * beadSize + beadSize,
    (size + numberOfWingLines + 1) * beadSize
  );
  pegs.push(centerPegBottomHalfCircle);
  addHalfCircle(centerPegBottomHalfCircle, 0);

  // Rotate the pegs back by -135 degrees so that the tip is on the center bottom.
  return new Pegboard(
    manufacturer,
    manufacturerId,
    rotateAndTranslateToTheTopLeft(pegs, -0.75 * Math.PI, beadSize),
    beadSize,
    0,
    45
  );
}
