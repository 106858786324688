import { Manufacturer } from './manufacturer';

export abstract class Bead {
  constructor(
    readonly id: string,
    readonly manufacturer: Manufacturer,
    readonly manufacturerId: string,
    readonly diameter: number
  ) {}
}

export class SingleColorBead extends Bead {
  constructor(
    id: string,
    manufacturer: Manufacturer,
    manufacturerId: string,
    diameter: number,
    readonly renderColor: string,
    readonly renderStrokeColor?: string
  ) {
    super(id, manufacturer, manufacturerId, diameter);
  }
}

export class BiColorBead extends Bead {
  constructor(
    id: string,
    manufacturer: Manufacturer,
    manufacturerId: string,
    diameter: number,
    readonly renderColor1: string,
    readonly renderColor2: string,
    readonly renderStrokeColor?: string
  ) {
    super(id, manufacturer, manufacturerId, diameter);
  }
}

export const beads = [
  new SingleColorBead(
    'H001',
    Manufacturer.h,
    'midi-01',
    5,
    '#fcfcfc',
    '#aaaaaa'
  ),
  new SingleColorBead('H002', Manufacturer.h, 'midi-02', 5, '#f4edb4'),
  new SingleColorBead('H003', Manufacturer.h, 'midi-03', 5, '#facc15'),
  new SingleColorBead('H004', Manufacturer.h, 'midi-04', 5, '#ef452e'),
  new SingleColorBead('H005', Manufacturer.h, 'midi-05', 5, '#c91f2a'),
  new SingleColorBead('H006', Manufacturer.h, 'midi-06', 5, '#f07eaa'),
  new SingleColorBead('H007', Manufacturer.h, 'midi-07', 5, '#562e92'),
  new SingleColorBead('H008', Manufacturer.h, 'midi-08', 5, '#2d3c94'),
  new SingleColorBead('H009', Manufacturer.h, 'midi-09', 5, '#3970bf'),
  new SingleColorBead('H010', Manufacturer.h, 'midi-10', 5, '#1bab54'),
  new SingleColorBead('H011', Manufacturer.h, 'midi-11', 5, '#22ad61'),
  new SingleColorBead('H012', Manufacturer.h, 'midi-12', 5, '#382521'),
  new SingleColorBead('H013', Manufacturer.h, 'midi-17', 5, '#7f8382'),
  new SingleColorBead('H014', Manufacturer.h, 'midi-18', 5, '#1c1a19'),
  new SingleColorBead('H015', Manufacturer.h, 'midi-20', 5, '#a1362b'),
  new SingleColorBead('H016', Manufacturer.h, 'midi-21', 5, '#c1553f'),
  new SingleColorBead('H017', Manufacturer.h, 'midi-22', 5, '#a11c2a'),
  new SingleColorBead('H019', Manufacturer.h, 'midi-26', 5, '#e69681'),
  new SingleColorBead('H020', Manufacturer.h, 'midi-27', 5, '#dab591'),
  new SingleColorBead('H021', Manufacturer.h, 'midi-28', 5, '#273722'),
  new SingleColorBead('H022', Manufacturer.h, 'midi-29', 5, '#de174c'),
  new SingleColorBead('H023', Manufacturer.h, 'midi-30', 5, '#4c161e'),
  new SingleColorBead('H024', Manufacturer.h, 'midi-31', 5, '#4aafae'),
  new SingleColorBead('H025', Manufacturer.h, 'midi-32', 5, '#ed1a6c'),
  new SingleColorBead('H026', Manufacturer.h, 'midi-33', 5, '#ed2b47'),
  new SingleColorBead('H027', Manufacturer.h, 'midi-34', 5, '#b8dc1e'),
  new SingleColorBead('H028', Manufacturer.h, 'midi-35', 5, '#ed3043'),
  new SingleColorBead('H029', Manufacturer.h, 'midi-36', 5, '#2561ae'),
  new SingleColorBead('H030', Manufacturer.h, 'midi-37', 5, '#2ab24b'),
  new SingleColorBead('H031', Manufacturer.h, 'midi-38', 5, '#f25f18'),
  new SingleColorBead('H032', Manufacturer.h, 'midi-42', 5, '#45b845'),
  new SingleColorBead('H033', Manufacturer.h, 'midi-43', 5, '#ebe555'),
  new SingleColorBead('H034', Manufacturer.h, 'midi-44', 5, '#f0555a'),
  new SingleColorBead('H035', Manufacturer.h, 'midi-45', 5, '#7c5cb2'),
  new SingleColorBead('H036', Manufacturer.h, 'midi-46', 5, '#4dc5eb'),
  new SingleColorBead('H037', Manufacturer.h, 'midi-47', 5, '#83cc2a'),
  new SingleColorBead('H038', Manufacturer.h, 'midi-48', 5, '#d05cb5'),
  new SingleColorBead('H039', Manufacturer.h, 'midi-49', 5, '#33bbcd'),
  new SingleColorBead('H040', Manufacturer.h, 'midi-60', 5, '#f58b25'),
  new SingleColorBead('H041', Manufacturer.h, 'midi-61', 5, '#b4593c'),
  new SingleColorBead('H042', Manufacturer.h, 'midi-62', 5, '#434244'),
  new SingleColorBead('H043', Manufacturer.h, 'midi-63', 5, '#382c23'),
  new SingleColorBead('H044', Manufacturer.h, 'midi-64', 5, '#d7d8d7'),
  new SingleColorBead('H045', Manufacturer.h, 'midi-70', 5, '#a6abad'),
  new SingleColorBead('H046', Manufacturer.h, 'midi-71', 5, '#353636'),
  new SingleColorBead('H047', Manufacturer.h, 'midi-75', 5, '#d2af8c'),
  new SingleColorBead('H048', Manufacturer.h, 'midi-76', 5, '#8f6754'),
  new SingleColorBead('H049', Manufacturer.h, 'midi-77', 5, '#dadbd3'),
  new SingleColorBead('H050', Manufacturer.h, 'midi-78', 5, '#f7a780'),
  new SingleColorBead('H051', Manufacturer.h, 'midi-79', 5, '#f36d3b'),
  new SingleColorBead('H052', Manufacturer.h, 'midi-82', 5, '#8c2b67'),
  new SingleColorBead('H053', Manufacturer.h, 'midi-83', 5, '#1481a0'),
  new SingleColorBead('H054', Manufacturer.h, 'midi-84', 5, '#47803c'),
  new SingleColorBead('H055', Manufacturer.h, 'midi-95', 5, '#e79ecc'),
  new SingleColorBead('H056', Manufacturer.h, 'midi-96', 5, '#cbacdb'),
  new SingleColorBead('H057', Manufacturer.h, 'midi-97', 5, '#92d0ee'),
  new SingleColorBead('H058', Manufacturer.h, 'midi-98', 5, '#9cdaad'),
  new SingleColorBead('H059', Manufacturer.h, 'midi-101', 5, '#6e8668'),
  new SingleColorBead('H060', Manufacturer.h, 'midi-102', 5, '#3d543f'),
  new BiColorBead(
    'H061',
    Manufacturer.h,
    'midi-1-5',
    5,
    '#fcfcfc',
    '#c91f2a',
    '#aaaaaa'
  ),
  new BiColorBead(
    'H062',
    Manufacturer.h,
    'midi-1-18',
    5,
    '#fcfcfc',
    '#1c1a19',
    '#aaaaaa'
  ),
  new BiColorBead(
    'H063',
    Manufacturer.h,
    'midi-1-49',
    5,
    '#fcfcfc',
    '#33bbcd',
    '#aaaaaa'
  ),
  new BiColorBead('H064', Manufacturer.h, 'midi-3-5', 5, '#facc15', '#c91f2a'),
  new BiColorBead('H065', Manufacturer.h, 'midi-7-48', 5, '#562e92', '#d05cb5'),
  new BiColorBead('H066', Manufacturer.h, 'midi-9-47', 5, '#3970bf', '#33bbcd'),
];

function parseColor(color: string): [number, number, number] {
  const m = color.match(/^#([0-9a-f]{6})$/i)![1];
  if (!m) {
    throw new Error(`Invalid color: ${color}`);
  }
  return [
    parseInt(m.substring(0, 2), 16) / 255,
    parseInt(m.substring(2, 4), 16) / 255,
    parseInt(m.substring(4, 6), 16) / 255,
  ];
}

function rgb2hsl(rgb: [number, number, number]) {
  const [r, g, b] = rgb;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s
    ? l === r
      ? (g - b) / s
      : l === g
      ? 2 + (b - r) / s
      : 4 + (r - g) / s
    : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
}

function colorKey(color: string): [number, number, number] {
  const [r, g, b] = parseColor(color);
  let [h, s, l] = rgb2hsl([r, g, b]);
  return [h, l, s];
}

function color(bead: Bead): string {
  if (bead instanceof SingleColorBead) {
    return bead.renderColor;
  } else if (bead instanceof BiColorBead) {
    return bead.renderColor1;
  }
  return '#000000';
}

function pleasingSort(a: Bead, b: Bead): number {
  if (a instanceof SingleColorBead && b instanceof BiColorBead) {
    return -1;
  } else if (a instanceof BiColorBead && b instanceof SingleColorBead) {
    return 1;
  }
  const keyA = colorKey(color(a));
  const keyB = colorKey(color(b));

  for (let i = 0; i < 3; i++) {
    if (keyA[i] !== keyB[i]) {
      return keyA[i] - keyB[i];
    }
  }
  return 0;
}

function sortBeads(beads: Array<Bead>): Array<Bead> {
  const sorted = [...beads];
  sorted.sort(pleasingSort);
  return sorted;
}

export const sortedBeads = sortBeads(beads);

export function getBeadById(id: string): Bead {
  return beads.find((b) => b.id === id)!;
}

export function filterBeads(
  beads: Array<Bead>,
  manufacturer: Manufacturer | undefined,
  diameter: number
): Array<Bead> {
  const filteredBeads = beads.filter(
    (b) =>
      (!manufacturer || b.manufacturer === manufacturer) &&
      b.diameter === diameter
  );
  return filteredBeads;
}
