import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg, orderTopDownLeftRight } from '../pegboard';

export function createSmallStar(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadDiameter: number
): Pegboard {
  const rowHeight = (beadDiameter / 2) * Math.sqrt(3); // height of equilateral triangle
  const middleRowY = beadDiameter + 10 * rowHeight;
  const middleRowStartX = (5 * beadDiameter) / 2 + beadDiameter;
  const pegs: Array<Peg> = [];
  for (let ix = 0; ix < 11; ix++) {
    pegs.push(new Peg(middleRowStartX + ix * beadDiameter, middleRowY));
  }

  for (let iy = 1; iy < 6; iy++) {
    for (let ix = 1; ix < 12 + iy; ix++) {
      pegs.push(
        new Peg(
          middleRowStartX - (iy * beadDiameter) / 2 + (ix - 1) * beadDiameter,
          middleRowY - iy * rowHeight
        )
      );
      pegs.push(
        new Peg(
          middleRowStartX - (iy * beadDiameter) / 2 + (ix - 1) * beadDiameter,
          middleRowY + iy * rowHeight
        )
      );
    }
  }
  for (let iy = 6; iy < 11; iy++) {
    for (let ix = 1; ix < 12 - iy; ix++) {
      pegs.push(
        new Peg(
          middleRowStartX + (iy * beadDiameter) / 2 + (ix - 1) * beadDiameter,
          middleRowY - iy * rowHeight
        )
      );
      pegs.push(
        new Peg(
          middleRowStartX + (iy * beadDiameter) / 2 + (ix - 1) * beadDiameter,
          middleRowY + iy * rowHeight
        )
      );
    }
  }
  pegs.sort(orderTopDownLeftRight);
  return new Pegboard(manufacturer, manufacturerId, pegs, beadDiameter, 0, 30);
}
