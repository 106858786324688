import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';
import { normalizedPegboard } from './pegboard_normalizer';

export function createCat(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadSize: number
): Pegboard {
  return normalizedPegboard(manufacturer, manufacturerId, beadSize, [
    new Peg(0.0704, 0.2472),
    new Peg(0.0751, 0.21062),
    new Peg(0.0751, 0.28274),
    new Peg(0.07981, 0.3188),
    new Peg(0.08294, 0.1756),
    new Peg(0.08765, 0.35591),
    new Peg(0.1028, 0.14581),
    new Peg(0.10646, 0.25922),
    new Peg(0.11064, 0.22316),
    new Peg(0.11482, 0.33082),
    new Peg(0.11691, 0.29424),
    new Peg(0.11743, 0.18815),
    new Peg(0.12005, 0.37107),
    new Peg(0.12946, 0.12177),
    new Peg(0.13677, 0.15888),
    new Peg(0.1467, 0.31462),
    new Peg(0.15036, 0.35121),
    new Peg(0.15036, 0.38884),
    new Peg(0.15925, 0.10296),
    new Peg(0.16604, 0.1385),
    new Peg(0.17492, 0.43483),
    new Peg(0.18015, 0.32821),
    new Peg(0.18433, 0.39929),
    new Peg(0.1859, 0.67785),
    new Peg(0.18694, 0.36375),
    new Peg(0.1906, 0.46723),
    new Peg(0.19113, 0.08623),
    new Peg(0.1974, 0.71182),
    new Peg(0.19844, 0.12177),
    new Peg(0.20106, 0.50486),
    new Peg(0.20106, 0.61095),
    new Peg(0.20524, 0.64754),
    new Peg(0.20837, 0.74736),
    new Peg(0.20994, 0.57542),
    new Peg(0.20994, 0.43587),
    new Peg(0.21099, 0.53935),
    new Peg(0.21569, 0.33082),
    new Peg(0.21987, 0.40033),
    new Peg(0.22353, 0.67837),
    new Peg(0.22405, 0.78185),
    new Peg(0.22457, 0.3648),
    new Peg(0.22666, 0.07892),
    new Peg(0.22876, 0.47089),
    new Peg(0.23398, 0.71234),
    new Peg(0.23503, 0.11498),
    new Peg(0.23712, 0.60991),
    new Peg(0.23973, 0.50643),
    new Peg(0.24548, 0.74736),
    new Peg(0.24548, 0.43535),
    new Peg(0.246, 0.31201),
    new Peg(0.24809, 0.54092),
    new Peg(0.24862, 0.64493),
    new Peg(0.25175, 0.57751),
    new Peg(0.25436, 0.39145),
    new Peg(0.25593, 0.34859),
    new Peg(0.26064, 0.78029),
    new Peg(0.26116, 0.67785),
    new Peg(0.26482, 0.07369),
    new Peg(0.26847, 0.46514),
    new Peg(0.27057, 0.1108),
    new Peg(0.27057, 0.28588),
    new Peg(0.27109, 0.71287),
    new Peg(0.27213, 0.60834),
    new Peg(0.27475, 0.5012),
    new Peg(0.27684, 0.41915),
    new Peg(0.27997, 0.32089),
    new Peg(0.28311, 0.53517),
    new Peg(0.28363, 0.74684),
    new Peg(0.2852, 0.3742),
    new Peg(0.28886, 0.57332),
    new Peg(0.29461, 0.63761),
    new Peg(0.29774, 0.67419),
    new Peg(0.29774, 0.78029),
    new Peg(0.29983, 0.05853),
    new Peg(0.30192, 0.47716),
    new Peg(0.30401, 0.27281),
    new Peg(0.30558, 0.0946),
    new Peg(0.3061, 0.34546),
    new Peg(0.3061, 0.70921),
    new Peg(0.30767, 0.60468),
    new Peg(0.30767, 0.4411),
    new Peg(0.30976, 0.13379),
    new Peg(0.31081, 0.40452),
    new Peg(0.31342, 0.30887),
    new Peg(0.31551, 0.54928),
    new Peg(0.31917, 0.51009),
    new Peg(0.31969, 0.74736),
    new Peg(0.32962, 0.37368),
    new Peg(0.33328, 0.77976),
    new Peg(0.33485, 0.58169),
    new Peg(0.33694, 0.07108),
    new Peg(0.33746, 0.47925),
    new Peg(0.33955, 0.26602),
    new Peg(0.34008, 0.10714),
    new Peg(0.34112, 0.71705),
    new Peg(0.34217, 0.33657),
    new Peg(0.34321, 0.44371),
    new Peg(0.34739, 0.40452),
    new Peg(0.34896, 0.30103),
    new Peg(0.35105, 0.55033),
    new Peg(0.35575, 0.13954),
    new Peg(0.35784, 0.50956),
    new Peg(0.35994, 0.74893),
    new Peg(0.36516, 0.36532),
    new Peg(0.37143, 0.08676),
    new Peg(0.37509, 0.46775),
    new Peg(0.37614, 0.43169),
    new Peg(0.37614, 0.26027),
    new Peg(0.37666, 0.33082),
    new Peg(0.38189, 0.3972),
    new Peg(0.38345, 0.53622),
    new Peg(0.38607, 0.29529),
    new Peg(0.38659, 0.1202),
    new Peg(0.39338, 0.49911),
    new Peg(0.40802, 0.37316),
    new Peg(0.40906, 0.41967),
    new Peg(0.41063, 0.33762),
    new Peg(0.4122, 0.45678),
    new Peg(0.41324, 0.26654),
    new Peg(0.41899, 0.52838),
    new Peg(0.42056, 0.3026),
    new Peg(0.43101, 0.49284),
    new Peg(0.44147, 0.38831),
    new Peg(0.44303, 0.3533),
    new Peg(0.44408, 0.4249),
    new Peg(0.4493, 0.45991),
    new Peg(0.4493, 0.27543),
    new Peg(0.45244, 0.54092),
    new Peg(0.45296, 0.31828),
    new Peg(0.46498, 0.50695),
    new Peg(0.47439, 0.40138),
    new Peg(0.477, 0.3648),
    new Peg(0.47962, 0.4364),
    new Peg(0.48171, 0.29163),
    new Peg(0.4838, 0.47298),
    new Peg(0.48432, 0.55817),
    new Peg(0.48902, 0.3303),
    new Peg(0.49739, 0.06637),
    new Peg(0.49895, 0.52368),
    new Peg(0.50261, 0.10191),
    new Peg(0.51045, 0.40556),
    new Peg(0.51359, 0.13588),
    new Peg(0.51411, 0.44894),
    new Peg(0.51463, 0.37054),
    new Peg(0.51516, 0.49127),
    new Peg(0.51777, 0.30313),
    new Peg(0.51829, 0.57071),
    new Peg(0.52927, 0.33866),
    new Peg(0.5324, 0.06899),
    new Peg(0.53293, 0.53674),
    new Peg(0.53815, 0.10453),
    new Peg(0.54286, 0.15679),
    new Peg(0.54495, 0.46932),
    new Peg(0.54599, 0.39668),
    new Peg(0.54756, 0.43221),
    new Peg(0.5486, 0.59057),
    new Peg(0.5486, 0.50538),
    new Peg(0.55749, 0.2864),
    new Peg(0.55801, 0.36114),
    new Peg(0.55853, 0.24982),
    new Peg(0.56219, 0.32194),
    new Peg(0.56376, 0.55817),
    new Peg(0.56742, 0.62141),
    new Peg(0.56899, 0.06533),
    new Peg(0.57056, 0.21532),
    new Peg(0.57317, 0.13797),
    new Peg(0.57421, 0.10244),
    new Peg(0.57578, 0.65642),
    new Peg(0.5763, 0.17874),
    new Peg(0.57892, 0.45155),
    new Peg(0.57944, 0.52524),
    new Peg(0.58101, 0.41654),
    new Peg(0.58205, 0.48761),
    new Peg(0.58989, 0.58221),
    new Peg(0.59041, 0.26915),
    new Peg(0.59146, 0.37838),
    new Peg(0.59146, 0.68883),
    new Peg(0.59198, 0.34337),
    new Peg(0.59303, 0.30522),
    new Peg(0.60244, 0.61566),
    new Peg(0.604, 0.08258),
    new Peg(0.60453, 0.15679),
    new Peg(0.60505, 0.23675),
    new Peg(0.60505, 0.12073),
    new Peg(0.60557, 0.54876),
    new Peg(0.60662, 0.20069),
    new Peg(0.61132, 0.65067),
    new Peg(0.61393, 0.46723),
    new Peg(0.61446, 0.75154),
    new Peg(0.61498, 0.43169),
    new Peg(0.61811, 0.50434),
    new Peg(0.61968, 0.716),
    new Peg(0.62491, 0.28222),
    new Peg(0.62543, 0.39197),
    new Peg(0.62648, 0.31776),
    new Peg(0.62648, 0.35382),
    new Peg(0.62752, 0.57803),
    new Peg(0.63013, 0.68151),
    new Peg(0.63066, 0.7829),
    new Peg(0.63432, 0.17717),
    new Peg(0.63588, 0.10139),
    new Peg(0.63693, 0.13902),
    new Peg(0.63745, 0.61357),
    new Peg(0.63797, 0.53465),
    new Peg(0.63954, 0.24929),
    new Peg(0.63954, 0.21376),
    new Peg(0.64738, 0.64858),
    new Peg(0.64738, 0.44894),
    new Peg(0.64999, 0.74893),
    new Peg(0.65209, 0.48761),
    new Peg(0.65574, 0.41235),
    new Peg(0.65679, 0.37525),
    new Peg(0.65731, 0.71444),
    new Peg(0.65731, 0.33657),
    new Peg(0.65731, 0.29999),
    new Peg(0.6594, 0.5634),
    new Peg(0.6662, 0.67994),
    new Peg(0.66776, 0.15783),
    new Peg(0.66776, 0.78238),
    new Peg(0.67038, 0.19599),
    new Peg(0.6709, 0.23257),
    new Peg(0.6709, 0.26759),
    new Peg(0.67142, 0.52002),
    new Peg(0.67195, 0.59632),
    new Peg(0.68031, 0.4641),
    new Peg(0.68083, 0.63395),
    new Peg(0.68553, 0.74057),
    new Peg(0.68815, 0.31776),
    new Peg(0.68815, 0.35434),
    new Peg(0.68867, 0.42751),
    new Peg(0.69128, 0.39145),
    new Peg(0.69546, 0.55085),
    new Peg(0.6986, 0.70032),
    new Peg(0.69912, 0.66426),
    new Peg(0.69912, 0.49859),
    new Peg(0.69964, 0.14059),
    new Peg(0.70174, 0.21376),
    new Peg(0.70226, 0.17822),
    new Peg(0.70278, 0.25034),
    new Peg(0.70383, 0.78185),
    new Peg(0.70644, 0.28692),
    new Peg(0.71741, 0.4641),
    new Peg(0.7216, 0.74579),
    new Peg(0.72264, 0.52786),
    new Peg(0.72316, 0.57751),
    new Peg(0.72421, 0.31933),
    new Peg(0.72421, 0.4296),
    new Peg(0.72421, 0.35487),
    new Peg(0.72787, 0.39302),
    new Peg(0.73205, 0.71234),
    new Peg(0.73832, 0.61409),
    new Peg(0.73884, 0.14006),
    new Peg(0.73884, 0.21323),
    new Peg(0.73884, 0.49598),
    new Peg(0.73884, 0.24877),
    new Peg(0.73989, 0.78185),
    new Peg(0.73989, 0.17822),
    new Peg(0.74302, 0.28692),
    new Peg(0.75086, 0.54876),
    new Peg(0.75243, 0.462),
    new Peg(0.75713, 0.74213),
    new Peg(0.75922, 0.5843),
    new Peg(0.75975, 0.35696),
    new Peg(0.75975, 0.31933),
    new Peg(0.76079, 0.42803),
    new Peg(0.76288, 0.39197),
    new Peg(0.76968, 0.51897),
    new Peg(0.7702, 0.22996),
    new Peg(0.77177, 0.26654),
    new Peg(0.77281, 0.15418),
    new Peg(0.77281, 0.19442),
    new Peg(0.77804, 0.61775),
    new Peg(0.78483, 0.4803),
    new Peg(0.78745, 0.55399),
    new Peg(0.78797, 0.65747),
    new Peg(0.79006, 0.29842),
    new Peg(0.79163, 0.33501),
    new Peg(0.79424, 0.37264),
    new Peg(0.79685, 0.12752),
    new Peg(0.80103, 0.589),
    new Peg(0.80313, 0.24825),
    new Peg(0.80313, 0.69248),
    new Peg(0.80313, 0.21271),
    new Peg(0.80522, 0.51793),
    new Peg(0.80574, 0.17508),
    new Peg(0.81567, 0.62454),
    new Peg(0.8188, 0.09773),
    new Peg(0.81985, 0.27908),
    new Peg(0.82142, 0.31567),
    new Peg(0.82299, 0.55556),
    new Peg(0.82403, 0.72593),
    new Peg(0.82403, 0.66008),
    new Peg(0.82403, 0.3533),
    new Peg(0.82717, 0.14738),
    new Peg(0.83605, 0.1986),
    new Peg(0.83657, 0.23466),
    new Peg(0.83919, 0.59528),
    new Peg(0.8418, 0.69248),
    new Peg(0.84859, 0.07578),
    new Peg(0.85016, 0.11759),
    new Peg(0.85487, 0.33553),
    new Peg(0.85539, 0.30051),
    new Peg(0.85591, 0.16933),
    new Peg(0.85643, 0.26497),
    new Peg(0.86009, 0.72698),
    new Peg(0.86009, 0.62559),
    new Peg(0.8648, 0.66322),
    new Peg(0.87734, 0.1986),
    new Peg(0.87838, 0.69667),
    new Peg(0.87891, 0.14111),
    new Peg(0.88466, 0.104),
    new Peg(0.88622, 0.06428),
    new Peg(0.8972, 0.64702),
    new Peg(0.89877, 0.72855),
    new Peg(0.9019, 0.16829),
    new Peg(0.91392, 0.13484),
    new Peg(0.91497, 0.69667),
    new Peg(0.92072, 0.09825),
    new Peg(0.92281, 0.06219),
    new Peg(0.93326, 0.66008),
    new Peg(0.93744, 0.72698),
    new Peg(0.95364, 0.69092),
  ]);
}
