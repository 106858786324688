import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';
import { normalizedPegboard } from './pegboard_normalizer';

export function createGirl(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadSize: number
): Pegboard {
  return normalizedPegboard(manufacturer, manufacturerId, beadSize, [
    new Peg(0.10744, 0.50389),
    new Peg(0.11063, 0.81496),
    new Peg(0.1117, 0.8842),
    new Peg(0.12874, 0.95132),
    new Peg(0.16922, 0.47619),
    new Peg(0.17881, 0.83307),
    new Peg(0.17988, 0.90125),
    new Peg(0.18307, 0.76489),
    new Peg(0.19053, 1.55108),
    new Peg(0.20331, 1.12816),
    new Peg(0.22568, 0.43571),
    new Peg(0.24273, 0.71801),
    new Peg(0.24273, 1.59689),
    new Peg(0.24273, 0.6477),
    new Peg(0.24379, 0.85544),
    new Peg(0.24593, 0.78513),
    new Peg(0.25232, 1.52871),
    new Peg(0.26936, 1.05998),
    new Peg(0.27043, 1.12709),
    new Peg(0.27149, 1.19207),
    new Peg(0.28108, 0.39842),
    new Peg(0.30878, 0.6477),
    new Peg(0.30984, 0.58059),
    new Peg(0.30984, 1.59476),
    new Peg(0.31091, 0.71908),
    new Peg(0.31091, 0.78726),
    new Peg(0.31837, 0.34303),
    new Peg(0.31837, 0.27591),
    new Peg(0.31837, 0.20667),
    new Peg(0.3205, 1.52871),
    new Peg(0.33541, 1.12816),
    new Peg(0.33541, 1.46373),
    new Peg(0.33648, 1.06104),
    new Peg(0.33754, 1.1974),
    new Peg(0.33754, 0.99712),
    new Peg(0.33861, 1.39661),
    new Peg(0.3418, 1.32843),
    new Peg(0.34287, 1.26238),
    new Peg(0.36844, 0.9364),
    new Peg(0.3695, 0.68712),
    new Peg(0.3695, 0.82348),
    new Peg(0.37057, 0.61894),
    new Peg(0.37163, 0.7521),
    new Peg(0.3727, 0.5465),
    new Peg(0.37589, 1.59689),
    new Peg(0.38441, 0.3409),
    new Peg(0.38548, 0.13955),
    new Peg(0.38548, 0.27485),
    new Peg(0.38655, 0.20667),
    new Peg(0.38761, 0.41121),
    new Peg(0.38974, 1.53297),
    new Peg(0.40359, 0.88207),
    new Peg(0.40359, 1.13029),
    new Peg(0.40466, 1.06424),
    new Peg(0.40466, 1.46373),
    new Peg(0.40572, 0.99925),
    new Peg(0.40572, 1.1974),
    new Peg(0.40679, 1.39555),
    new Peg(0.40998, 1.32843),
    new Peg(0.40998, 1.26238),
    new Peg(0.43448, 0.81922),
    new Peg(0.43661, 0.94066),
    new Peg(0.43768, 0.66901),
    new Peg(0.43981, 0.74571),
    new Peg(0.44088, 0.59124),
    new Peg(0.44301, 0.51774),
    new Peg(0.44407, 0.37605),
    new Peg(0.44407, 0.1044),
    new Peg(0.4462, 0.23969),
    new Peg(0.44727, 0.30681),
    new Peg(0.44727, 0.44636),
    new Peg(0.44727, 0.17258),
    new Peg(0.4707, 0.88207),
    new Peg(0.4707, 1.06211),
    new Peg(0.4707, 1.13029),
    new Peg(0.47177, 0.99606),
    new Peg(0.47177, 1.1974),
    new Peg(0.5016, 0.82028),
    new Peg(0.50266, 0.14062),
    new Peg(0.50373, 0.34409),
    new Peg(0.50373, 0.41227),
    new Peg(0.50479, 0.68073),
    new Peg(0.50479, 0.20773),
    new Peg(0.50479, 0.27591),
    new Peg(0.50586, 0.48152),
    new Peg(0.50586, 0.61575),
    new Peg(0.50586, 0.93747),
    new Peg(0.50586, 0.5465),
    new Peg(0.50692, 0.74678),
    new Peg(0.53782, 0.87781),
    new Peg(0.53888, 0.99393),
    new Peg(0.53995, 1.06104),
    new Peg(0.54101, 1.19634),
    new Peg(0.54101, 1.12816),
    new Peg(0.56019, 0.17471),
    new Peg(0.56019, 0.10973),
    new Peg(0.56019, 0.3132),
    new Peg(0.56019, 0.37818),
    new Peg(0.56125, 0.24395),
    new Peg(0.56125, 0.4453),
    new Peg(0.56871, 0.51667),
    new Peg(0.57084, 0.81496),
    new Peg(0.57191, 0.59337),
    new Peg(0.57297, 0.66688),
    new Peg(0.5751, 0.74571),
    new Peg(0.5751, 0.9364),
    new Peg(0.60174, 1.26878),
    new Peg(0.60493, 1.33482),
    new Peg(0.60493, 1.40194),
    new Peg(0.60493, 0.99499),
    new Peg(0.606, 0.87888),
    new Peg(0.60706, 1.06104),
    new Peg(0.60919, 1.20166),
    new Peg(0.60919, 1.13029),
    new Peg(0.61026, 1.46799),
    new Peg(0.61559, 1.53404),
    new Peg(0.61665, 0.28017),
    new Peg(0.61665, 0.34622),
    new Peg(0.61772, 0.14382),
    new Peg(0.61878, 0.41334),
    new Peg(0.61985, 0.21093),
    new Peg(0.63156, 0.54544),
    new Peg(0.63902, 0.81709),
    new Peg(0.63902, 0.68606),
    new Peg(0.64009, 0.61681),
    new Peg(0.64115, 1.59476),
    new Peg(0.64222, 0.93534),
    new Peg(0.64222, 0.7521),
    new Peg(0.66779, 1.26451),
    new Peg(0.67205, 1.33163),
    new Peg(0.67311, 1.40087),
    new Peg(0.67418, 1.06317),
    new Peg(0.67631, 1.19953),
    new Peg(0.67737, 1.12922),
    new Peg(0.67737, 0.99286),
    new Peg(0.6795, 1.46799),
    new Peg(0.68483, 0.27591),
    new Peg(0.6859, 0.34622),
    new Peg(0.6859, 0.2088),
    new Peg(0.69016, 1.53191),
    new Peg(0.69655, 0.58059),
    new Peg(0.69868, 0.64877),
    new Peg(0.69974, 0.71695),
    new Peg(0.69974, 0.78513),
    new Peg(0.7072, 1.59476),
    new Peg(0.72744, 0.39736),
    new Peg(0.74129, 1.06104),
    new Peg(0.74555, 1.12603),
    new Peg(0.74768, 1.19847),
    new Peg(0.7594, 1.53084),
    new Peg(0.76366, 0.84905),
    new Peg(0.76366, 0.783),
    new Peg(0.76579, 0.6477),
    new Peg(0.76686, 0.71588),
    new Peg(0.77645, 1.59689),
    new Peg(0.78071, 0.44104),
    new Peg(0.81054, 1.12816),
    new Peg(0.82545, 1.54895),
    new Peg(0.82652, 0.82774),
    new Peg(0.82652, 0.89699),
    new Peg(0.82758, 0.75743),
    new Peg(0.83717, 0.47939),
    new Peg(0.87978, 0.94386),
    new Peg(0.8915, 0.87568),
    new Peg(0.8915, 0.80643),
    new Peg(0.89896, 0.50495),
  ]);
}
