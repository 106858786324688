import { ReactNode, memo, useState } from 'react';
import { Bead as BeadModel, BiColorBead, SingleColorBead } from '../model/bead';
import { v4 as uuidv4 } from 'uuid';
import { svgStyles } from '../svg/styles';
import Random from '../common/random/random';
import BeadPatternRenderingOptions from './bead_pattern_rendering_options';

interface BeadProps {
  bead: BeadModel;
  svgIdPrefix: string;
  randomSeed?: number;
  renderingOptions: BeadPatternRenderingOptions;
}

function _Bead({ bead, randomSeed, renderingOptions }: BeadProps) {
  const strokeWidth = 0.1;
  const c = bead.diameter / 2; // center
  const ro = bead.diameter / 2 - strokeWidth / 2; // radius outer circle
  const ri = 0.3 * ro; // radius inner circle
  if (bead instanceof SingleColorBead) {
    // Draw two half circles for the outer and the inner circle.
    // They both start in the east and go counter clockwise.
    return (
      <path
        fill={bead.renderColor}
        fillRule="evenodd"
        stroke={
          (renderingOptions.beadOutline && bead.renderStrokeColor) ||
          bead.renderColor
        }
        strokeWidth={strokeWidth}
        d={`
      M ${c + ro}, ${c} 
      A ${ro},${ro} 0 1,1 ${c - ro},${c}
      A ${ro},${ro} 0 1,1 ${c + ro},${c} Z

      M ${c + ri}, ${c}
      A ${ri},${ri} 0 1,0 ${c - ri},${c}
      A ${ri},${ri} 0 1,0 ${c + ri},${c} Z
      `}
      />
    );
  } else if (bead instanceof BiColorBead) {
    // Draw six segments, alternating between color1 and color2.
    // The segment start on the outer circle, goes counter clockwise to the end of the segment.
    // Then it goes straight to the inner circle and back, clock wise to the start of the segment on the inner circle
    const segments: Array<ReactNode> = [];
    for (let i = 0; i < 6; i++) {
      const [p1x, p1y] = [
        c + ro * Math.cos((i * Math.PI) / 3),
        c + ro * Math.sin((i * Math.PI) / 3),
      ];
      const [p2x, p2y] = [
        c + ro * Math.cos(((i + 1) * Math.PI) / 3),
        c + ro * Math.sin(((i + 1) * Math.PI) / 3),
      ];
      const [p3x, p3y] = [
        c + ri * Math.cos(((i + 1) * Math.PI) / 3),
        c + ri * Math.sin(((i + 1) * Math.PI) / 3),
      ];
      const [p4x, p4y] = [
        c + ri * Math.cos((i * Math.PI) / 3),
        c + ri * Math.sin((i * Math.PI) / 3),
      ];

      segments.push(
        <path
          key={i}
          fill={i % 2 === 0 ? bead.renderColor1 : bead.renderColor2}
          stroke={
            (renderingOptions.beadOutline && bead.renderStrokeColor) ||
            (i % 2 === 0 ? bead.renderColor1 : bead.renderColor2)
          }
          strokeWidth={strokeWidth}
          d={`
        M ${p1x},${p1y}
        A ${ro},${ro} 0 0,1 ${p2x},${p2y}
        L ${p3x} ${p3y}
        A ${ri},${ri} 0 0,0 ${p4x},${p4y} Z 
        `}
        />
      );
    }

    const rotation =
      randomSeed !== undefined
        ? new Random(1337 + randomSeed! * 19).nextInt(360)
        : 0;

    return <g transform={`rotate(${rotation} ${c} ${c})`}>{segments}</g>;
  } else {
    return <></>;
  }
}

export const Bead = memo(_Bead);

interface BeadSvgProps {
  bead: BeadModel;
  renderingOptions: BeadPatternRenderingOptions;
}

function _BeadSvg({ bead, renderingOptions }: BeadSvgProps) {
  // Set the id prefix once to a random value
  const [svgIdPrefix] = useState(uuidv4());
  return (
    <svg
      viewBox={`0 0 ${bead.diameter} ${bead.diameter}`}
      style={{ width: '100%', height: '100%' }}>
      {svgStyles(svgIdPrefix)}
      <Bead
        bead={bead}
        svgIdPrefix={svgIdPrefix}
        renderingOptions={renderingOptions}
      />
    </svg>
  );
}

export const BeadSvg = memo(_BeadSvg);
