import React, { memo } from 'react';
import { Bead as BeadModel } from '../model/bead';
import { Peg as PegModel } from '../model/pegboard';
import { Bead } from './bead';
import BeadPatternRenderingOptions from './bead_pattern_rendering_options';

interface PegProps {
  peg: PegModel;
  bead?: BeadModel;
  beadDiameter: number;
  svgIdPrefix: string;
  ix: number;
  renderingOptions: BeadPatternRenderingOptions;

  onPegClick?: (peg: PegModel, bead?: BeadModel) => void;
}

function _Peg({
  peg,
  bead,
  beadDiameter,
  svgIdPrefix,
  onPegClick = undefined,
  renderingOptions,
  ix,
}: PegProps) {
  const translate = `translate(${peg.x - beadDiameter / 2}, ${
    peg.y - beadDiameter / 2
  })`;
  return (
    <g
      data-peg-ix={ix}
      transform={translate}
      onMouseDown={(event) => onPegClick && onPegClick(peg, bead)}>
      {/* Opaque circle to capture mouse events*/}
      {renderingOptions.interactive && (
        <circle
          cx={beadDiameter / 2}
          cy={beadDiameter / 2}
          r={beadDiameter / 2}
          fillOpacity={0}
        />
      )}
      {!bead && renderingOptions.emptyPegs && (
        <circle
          cx={beadDiameter / 2}
          cy={beadDiameter / 2}
          r={beadDiameter / 8}
          fill={'#aaaaaa'}
        />
      )}
      {bead && (
        <Bead
          bead={bead}
          svgIdPrefix={svgIdPrefix}
          randomSeed={ix}
          renderingOptions={renderingOptions}
        />
      )}
    </g>
  );
}

export const Peg = memo(_Peg);
