import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg, orderTopDownLeftRight } from '../pegboard';

export function createHexagon(
  manufacturer: Manufacturer,
  manufacturerId: string,
  side: number,
  beadDiameter: number
): Pegboard {
  const rowHeight = (beadDiameter / 2) * Math.sqrt(3); // height of equilateral triangle
  const middleRowY = beadDiameter + (side - 1) * rowHeight;
  const pegs: Array<Peg> = [];

  // This is the middle row
  for (let ix = 1; ix < 2 * side; ix++) {
    pegs.push(new Peg(ix * beadDiameter, middleRowY));
  }

  // Add rows above and below the middle row
  for (let iy = 1; iy < side; iy++) {
    for (let ix = 1; ix < 2 * side - iy; ix++) {
      pegs.push(
        new Peg(
          ix * beadDiameter + (iy * beadDiameter) / 2,
          middleRowY - iy * rowHeight
        )
      );
      pegs.push(
        new Peg(
          ix * beadDiameter + (iy * beadDiameter) / 2,
          middleRowY + iy * rowHeight
        )
      );
    }
  }
  pegs.sort(orderTopDownLeftRight);
  return new Pegboard(manufacturer, manufacturerId, pegs, beadDiameter, 0, 30);
}
