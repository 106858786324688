import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';
import { normalizedPegboard } from './pegboard_normalizer';

export function createEgg(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadSize: number
): Pegboard {
  return normalizedPegboard(manufacturer, manufacturerId, beadSize, [
    new Peg(0.03799, 0.73202),
    new Peg(0.03799, 0.8308),
    new Peg(0.03631, 0.78267),
    new Peg(0.04306, 0.87893),
    new Peg(0.0439, 0.6822),
    new Peg(0.04981, 0.63154),
    new Peg(0.0591, 0.92536),
    new Peg(0.05826, 0.58004),
    new Peg(0.07514, 0.53107),
    new Peg(0.07937, 0.97011),
    new Peg(0.08612, 0.78183),
    new Peg(0.08781, 0.82996),
    new Peg(0.0895, 0.73286),
    new Peg(0.09203, 0.6822),
    new Peg(0.09034, 0.48463),
    new Peg(0.09541, 0.87808),
    new Peg(0.09878, 0.63408),
    new Peg(0.10301, 1.01402),
    new Peg(0.10723, 0.58511),
    new Peg(0.10976, 0.92705),
    new Peg(0.10638, 0.4382),
    new Peg(0.1258, 0.53614),
    new Peg(0.12918, 1.05623),
    new Peg(0.12834, 0.39514),
    new Peg(0.13509, 0.9718),
    new Peg(0.13678, 0.7869),
    new Peg(0.13847, 0.48886),
    new Peg(0.13931, 0.73877),
    new Peg(0.141, 0.83502),
    new Peg(0.14184, 0.69065),
    new Peg(0.1486, 0.64083),
    new Peg(0.14944, 0.88315),
    new Peg(0.14944, 0.35208),
    new Peg(0.15451, 1.0976),
    new Peg(0.1562, 0.44157),
    new Peg(0.15873, 1.01486),
    new Peg(0.15957, 0.59271),
    new Peg(0.16802, 0.92874),
    new Peg(0.17308, 0.54542),
    new Peg(0.17899, 0.39851),
    new Peg(0.17477, 0.31071),
    new Peg(0.18744, 0.79112),
    new Peg(0.18828, 0.74299),
    new Peg(0.18744, 1.05876),
    new Peg(0.18913, 0.4973),
    new Peg(0.1925, 0.69487),
    new Peg(0.18997, 1.13475),
    new Peg(0.19166, 0.9718),
    new Peg(0.1925, 0.83924),
    new Peg(0.19841, 0.6459),
    new Peg(0.20095, 0.27102),
    new Peg(0.20517, 0.44917),
    new Peg(0.20263, 0.88906),
    new Peg(0.2077, 0.35799),
    new Peg(0.21192, 0.59524),
    new Peg(0.21868, 1.01317),
    new Peg(0.22121, 1.09422),
    new Peg(0.22543, 0.93296),
    new Peg(0.22627, 0.5488),
    new Peg(0.22796, 0.40442),
    new Peg(0.22881, 1.16684),
    new Peg(0.22796, 0.2305),
    new Peg(0.23219, 0.31662),
    new Peg(0.23725, 0.78774),
    new Peg(0.2381, 0.73961),
    new Peg(0.24147, 0.68896),
    new Peg(0.24316, 0.84009),
    new Peg(0.24823, 0.50321),
    new Peg(0.24992, 0.63914),
    new Peg(0.25245, 0.97518),
    new Peg(0.25245, 1.05032),
    new Peg(0.25329, 0.45255),
    new Peg(0.25414, 0.88821),
    new Peg(0.25751, 0.27524),
    new Peg(0.25751, 0.36052),
    new Peg(0.2592, 0.1925),
    new Peg(0.25836, 1.12884),
    new Peg(0.26258, 0.59271),
    new Peg(0.27187, 1.19554),
    new Peg(0.27778, 0.93043),
    new Peg(0.282, 0.54542),
    new Peg(0.28538, 0.79196),
    new Peg(0.28284, 0.40696),
    new Peg(0.28453, 0.23472),
    new Peg(0.28453, 1.01317),
    new Peg(0.28538, 0.31493),
    new Peg(0.28622, 0.74046),
    new Peg(0.28707, 1.08578),
    new Peg(0.2896, 0.6898),
    new Peg(0.29213, 0.84262),
    new Peg(0.29466, 0.1562),
    new Peg(0.30142, 0.45339),
    new Peg(0.30226, 0.64168),
    new Peg(0.30311, 1.15502),
    new Peg(0.30395, 0.50236),
    new Peg(0.30648, 0.96842),
    new Peg(0.31071, 0.59102),
    new Peg(0.31155, 0.89412),
    new Peg(0.30817, 0.35968),
    new Peg(0.3183, 1.04779),
    new Peg(0.31915, 0.27356),
    new Peg(0.32084, 1.21496),
    new Peg(0.31999, 0.2001),
    new Peg(0.32844, 1.11196),
    new Peg(0.32759, 0.12074),
    new Peg(0.33097, 0.40274),
    new Peg(0.33266, 0.54542),
    new Peg(0.3335, 0.79281),
    new Peg(0.33435, 0.74215),
    new Peg(0.34026, 1.00388),
    new Peg(0.33857, 0.69318),
    new Peg(0.34026, 0.32253),
    new Peg(0.34279, 0.84515),
    new Peg(0.34363, 0.93549),
    new Peg(0.34954, 0.45339),
    new Peg(0.3487, 1.17443),
    new Peg(0.35039, 0.64505),
    new Peg(0.35292, 0.23556),
    new Peg(0.35377, 0.16464),
    new Peg(0.35968, 1.07396),
    new Peg(0.36136, 0.50236),
    new Peg(0.36221, 0.59777),
    new Peg(0.36474, 0.88906),
    new Peg(0.36559, 0.09119),
    new Peg(0.36981, 0.36136),
    new Peg(0.3715, 1.231),
    new Peg(0.37487, 1.12969),
    new Peg(0.37487, 0.28369),
    new Peg(0.37909, 0.97349),
    new Peg(0.38247, 0.40865),
    new Peg(0.38247, 1.02921),
    new Peg(0.38332, 0.79787),
    new Peg(0.38332, 0.55302),
    new Peg(0.38416, 0.7489),
    new Peg(0.38838, 0.69993),
    new Peg(0.39092, 0.13256),
    new Peg(0.39429, 0.84684),
    new Peg(0.3926, 0.19419),
    new Peg(0.39851, 0.46184),
    new Peg(0.39851, 0.65181),
    new Peg(0.40105, 0.92874),
    new Peg(0.40105, 0.24232),
    new Peg(0.40189, 1.19048),
    new Peg(0.40358, 0.32506),
    new Peg(0.40527, 1.09),
    new Peg(0.40611, 0.06248),
    new Peg(0.41371, 0.50912),
    new Peg(0.41202, 0.60368),
    new Peg(0.42215, 1.23945),
    new Peg(0.42469, 1.14404),
    new Peg(0.42469, 0.88652),
    new Peg(0.42553, 0.37909),
    new Peg(0.42553, 0.99544),
    new Peg(0.42975, 0.28031),
    new Peg(0.42891, 1.04779),
    new Peg(0.42975, 0.16126),
    new Peg(0.43229, 0.80041),
    new Peg(0.43398, 0.42722),
    new Peg(0.43398, 0.10301),
    new Peg(0.43482, 0.74806),
    new Peg(0.43398, 0.55387),
    new Peg(0.43989, 0.69571),
    new Peg(0.44495, 0.21952),
    new Peg(0.44664, 0.95069),
    new Peg(0.45255, 0.64843),
    new Peg(0.45255, 1.19639),
    new Peg(0.45508, 0.32084),
    new Peg(0.45255, 1.10267),
    new Peg(0.45255, 0.04137),
    new Peg(0.45424, 0.846),
    new Peg(0.45677, 0.47535),
    new Peg(0.46099, 0.59946),
    new Peg(0.47197, 1.24367),
    new Peg(0.47366, 0.90848),
    new Peg(0.47619, 0.26174),
    new Peg(0.47619, 0.52178),
    new Peg(0.47619, 1.15248),
    new Peg(0.47703, 0.77339),
    new Peg(0.47788, 0.18068),
    new Peg(0.47788, 1.00811),
    new Peg(0.47957, 0.36221),
    new Peg(0.48126, 0.41287),
    new Peg(0.47957, 0.13087),
    new Peg(0.47957, 0.08274),
    new Peg(0.48041, 1.05623),
    new Peg(0.48041, 0.72442),
    new Peg(0.50236, 1.10605),
    new Peg(0.50236, 1.20399),
    new Peg(0.50321, 0.9566),
    new Peg(0.50321, 0.81391),
    new Peg(0.50405, 0.56991),
    new Peg(0.50405, 0.62563),
    new Peg(0.5049, 0.22205),
    new Peg(0.5049, 0.03377),
    new Peg(0.5049, 0.86373),
    new Peg(0.5049, 0.46184),
    new Peg(0.50574, 0.30733),
    new Peg(0.50659, 0.67545),
    new Peg(0.53107, 1.05623),
    new Peg(0.53191, 0.08274),
    new Peg(0.53276, 0.4154),
    new Peg(0.53276, 0.72442),
    new Peg(0.53276, 0.3639),
    new Peg(0.5336, 0.18068),
    new Peg(0.5336, 1.00811),
    new Peg(0.53445, 0.13087),
    new Peg(0.53529, 1.15248),
    new Peg(0.53529, 0.26174),
    new Peg(0.53529, 0.52178),
    new Peg(0.5336, 0.77508),
    new Peg(0.53783, 0.90848),
    new Peg(0.53951, 1.24367),
    new Peg(0.55049, 0.59946),
    new Peg(0.55471, 0.47535),
    new Peg(0.55724, 0.846),
    new Peg(0.55809, 0.04222),
    new Peg(0.55724, 0.32168),
    new Peg(0.55893, 1.10267),
    new Peg(0.55893, 1.19639),
    new Peg(0.56062, 0.65181),
    new Peg(0.56484, 0.95069),
    new Peg(0.564, 0.21952),
    new Peg(0.57329, 0.5564),
    new Peg(0.57497, 0.69824),
    new Peg(0.57666, 0.74806),
    new Peg(0.57751, 0.10301),
    new Peg(0.5792, 0.43144),
    new Peg(0.57751, 0.80041),
    new Peg(0.58173, 0.16126),
    new Peg(0.58257, 1.04779),
    new Peg(0.58088, 0.28031),
    new Peg(0.58595, 0.37909),
    new Peg(0.58595, 0.99544),
    new Peg(0.5868, 1.14404),
    new Peg(0.5868, 0.88652),
    new Peg(0.58933, 1.23945),
    new Peg(0.59946, 0.5125),
    new Peg(0.59946, 0.60368),
    new Peg(0.60453, 0.06163),
    new Peg(0.60706, 1.08916),
    new Peg(0.6079, 0.32506),
    new Peg(0.60959, 1.19048),
    new Peg(0.61044, 0.24232),
    new Peg(0.61044, 0.92874),
    new Peg(0.61297, 0.65181),
    new Peg(0.61466, 0.46437),
    new Peg(0.61719, 0.19419),
    new Peg(0.61719, 0.84684),
    new Peg(0.62057, 0.13256),
    new Peg(0.62479, 0.69909),
    new Peg(0.62732, 0.74806),
    new Peg(0.62817, 0.79787),
    new Peg(0.62732, 0.55218),
    new Peg(0.62901, 0.40865),
    new Peg(0.62901, 1.02921),
    new Peg(0.63239, 0.97349),
    new Peg(0.63661, 0.28369),
    new Peg(0.63661, 1.13053),
    new Peg(0.63999, 1.231),
    new Peg(0.64168, 0.36136),
    new Peg(0.64505, 0.0895),
    new Peg(0.64674, 0.88906),
    new Peg(0.64927, 0.59608),
    new Peg(0.65012, 0.50236),
    new Peg(0.65434, 1.07396),
    new Peg(0.65941, 0.16464),
    new Peg(0.65856, 0.23472),
    new Peg(0.66109, 0.64336),
    new Peg(0.66278, 1.17443),
    new Peg(0.66278, 0.45424),
    new Peg(0.66869, 0.93465),
    new Peg(0.66954, 0.84431),
    new Peg(0.67123, 0.32253),
    new Peg(0.67291, 0.69318),
    new Peg(0.67207, 1.00388),
    new Peg(0.67714, 0.74215),
    new Peg(0.67798, 0.79281),
    new Peg(0.67882, 0.54542),
    new Peg(0.68051, 0.40611),
    new Peg(0.68305, 0.12158),
    new Peg(0.68305, 1.1128),
    new Peg(0.69065, 1.21496),
    new Peg(0.69402, 0.2001),
    new Peg(0.69233, 0.27356),
    new Peg(0.69571, 1.04948),
    new Peg(0.69993, 0.89412),
    new Peg(0.70078, 0.59102),
    new Peg(0.70078, 0.36136),
    new Peg(0.70584, 0.96927),
    new Peg(0.70753, 0.50236),
    new Peg(0.70838, 1.15502),
    new Peg(0.70922, 0.64168),
    new Peg(0.71091, 0.45086),
    new Peg(0.71682, 0.1562),
    new Peg(0.71935, 0.84178),
    new Peg(0.72188, 0.6898),
    new Peg(0.72526, 1.08831),
    new Peg(0.72526, 0.74046),
    new Peg(0.72611, 0.31493),
    new Peg(0.72695, 0.23556),
    new Peg(0.73033, 1.01402),
    new Peg(0.73286, 0.40611),
    new Peg(0.72695, 0.78943),
    new Peg(0.72948, 0.54542),
    new Peg(0.7337, 0.92958),
    new Peg(0.73961, 1.19554),
    new Peg(0.7489, 0.59271),
    new Peg(0.75312, 0.19335),
    new Peg(0.75312, 1.12884),
    new Peg(0.75397, 0.27524),
    new Peg(0.75397, 0.36052),
    new Peg(0.7565, 0.88652),
    new Peg(0.75903, 0.45255),
    new Peg(0.76157, 1.05201),
    new Peg(0.75903, 0.97518),
    new Peg(0.76157, 0.63914),
    new Peg(0.76579, 0.50236),
    new Peg(0.76917, 0.8384),
    new Peg(0.77085, 0.68896),
    new Peg(0.77339, 0.73961),
    new Peg(0.77508, 0.78858),
    new Peg(0.78183, 0.31493),
    new Peg(0.78267, 0.23219),
    new Peg(0.78267, 1.16684),
    new Peg(0.78352, 0.40442),
    new Peg(0.78521, 0.5488),
    new Peg(0.78605, 0.93296),
    new Peg(0.79027, 1.09422),
    new Peg(0.79365, 1.01317),
    new Peg(0.79956, 0.59524),
    new Peg(0.80378, 0.35799),
    new Peg(0.80885, 0.88906),
    new Peg(0.80716, 0.44917),
    new Peg(0.80969, 0.27187),
    new Peg(0.81307, 0.64421),
    new Peg(0.81898, 0.83924),
    new Peg(0.81982, 0.9718),
    new Peg(0.82151, 1.13475),
    new Peg(0.82067, 0.4973),
    new Peg(0.82151, 0.69318),
    new Peg(0.82405, 1.05876),
    new Peg(0.82405, 0.74215),
    new Peg(0.82573, 0.79027),
    new Peg(0.83587, 0.31155),
    new Peg(0.83418, 0.39767),
    new Peg(0.8384, 0.54542),
    new Peg(0.84347, 0.92874),
    new Peg(0.85191, 0.59271),
    new Peg(0.85275, 1.01486),
    new Peg(0.85529, 0.44157),
    new Peg(0.85866, 0.35377),
    new Peg(0.85697, 1.0976),
    new Peg(0.8612, 0.88315),
    new Peg(0.86288, 0.64083),
    new Peg(0.87133, 0.6898),
    new Peg(0.87048, 0.83418),
    new Peg(0.87302, 0.73793),
    new Peg(0.87048, 0.48886),
    new Peg(0.8747, 0.78605),
    new Peg(0.87639, 0.9718),
    new Peg(0.88399, 0.39514),
    new Peg(0.8823, 1.05623),
    new Peg(0.88652, 0.53614),
    new Peg(0.90594, 0.4382),
    new Peg(0.90172, 0.92705),
    new Peg(0.90426, 0.58511),
    new Peg(0.90932, 1.01486),
    new Peg(0.9127, 0.63408),
    new Peg(0.91608, 0.87808),
    new Peg(0.91861, 0.48463),
    new Peg(0.91945, 0.6822),
    new Peg(0.92199, 0.73286),
    new Peg(0.92367, 0.82996),
    new Peg(0.92536, 0.78183),
    new Peg(0.93212, 0.97011),
    new Peg(0.93634, 0.53023),
    new Peg(0.95323, 0.92452),
    new Peg(0.95323, 0.58004),
    new Peg(0.96167, 0.63154),
    new Peg(0.96758, 0.6822),
    new Peg(0.96758, 0.87808),
    new Peg(0.97518, 0.78099),
    new Peg(0.97349, 0.73202),
    new Peg(0.97349, 0.82996),
  ]);
}
