import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';
import { normalizedPegboard } from './pegboard_normalizer';

export function createFlower(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadSize: number
): Pegboard {
  return normalizedPegboard(manufacturer, manufacturerId, beadSize, [
    new Peg(0.03248, 0.41944),
    new Peg(0.03913, 0.36692),
    new Peg(0.04431, 0.469),
    new Peg(0.06354, 0.32401),
    new Peg(0.07094, 0.51339),
    new Peg(0.08204, 0.4335),
    new Peg(0.08648, 0.38245),
    new Peg(0.10127, 0.28776),
    new Peg(0.10719, 0.4801),
    new Peg(0.11163, 0.54298),
    new Peg(0.11385, 0.33807),
    new Peg(0.13308, 0.39947),
    new Peg(0.1353, 1.00089),
    new Peg(0.14418, 0.44681),
    new Peg(0.14936, 0.50969),
    new Peg(0.14936, 0.26705),
    new Peg(0.15675, 0.7723),
    new Peg(0.15897, 0.82409),
    new Peg(0.16045, 0.3144),
    new Peg(0.16045, 0.56073),
    new Peg(0.16785, 1.04305),
    new Peg(0.17007, 0.72126),
    new Peg(0.17155, 0.36396),
    new Peg(0.18043, 0.87439),
    new Peg(0.18191, 0.41204),
    new Peg(0.18265, 0.98609),
    new Peg(0.193, 0.46087),
    new Peg(0.19818, 1.083),
    new Peg(0.20114, 0.67909),
    new Peg(0.20114, 0.26409),
    new Peg(0.20188, 0.50895),
    new Peg(0.20706, 0.77822),
    new Peg(0.2115, 0.31809),
    new Peg(0.21224, 0.5563),
    new Peg(0.21519, 0.82852),
    new Peg(0.21593, 1.03196),
    new Peg(0.21667, 0.9099),
    new Peg(0.21889, 0.37875),
    new Peg(0.22407, 0.72866),
    new Peg(0.22629, 1.12295),
    new Peg(0.23073, 0.42906),
    new Peg(0.23147, 0.98091),
    new Peg(0.24404, 0.65246),
    new Peg(0.247, 0.87143),
    new Peg(0.24774, 0.48676),
    new Peg(0.24774, 0.28185),
    new Peg(0.25292, 1.07856),
    new Peg(0.2544, 0.34547),
    new Peg(0.25514, 0.78192),
    new Peg(0.26032, 0.53928),
    new Peg(0.26402, 0.69685),
    new Peg(0.26402, 0.93135),
    new Peg(0.26476, 1.03048),
    new Peg(0.26624, 1.15032),
    new Peg(0.27437, 0.82926),
    new Peg(0.27585, 0.39503),
    new Peg(0.27733, 0.44755),
    new Peg(0.28325, 0.98387),
    new Peg(0.28547, 0.74049),
    new Peg(0.29139, 0.31292),
    new Peg(0.29287, 0.64802),
    new Peg(0.29657, 1.10667),
    new Peg(0.29731, 0.88845),
    new Peg(0.30397, 0.7871),
    new Peg(0.30692, 1.05415),
    new Peg(0.3084, 1.17399),
    new Peg(0.31062, 0.35878),
    new Peg(0.31062, 0.53188),
    new Peg(0.31136, 0.48306),
    new Peg(0.31432, 0.93801),
    new Peg(0.3158, 0.69167),
    new Peg(0.3232, 0.83444),
    new Peg(0.32468, 0.43128),
    new Peg(0.32912, 1.00459),
    new Peg(0.33134, 0.61843),
    new Peg(0.3343, 0.74715),
    new Peg(0.33947, 1.13404),
    new Peg(0.34613, 0.87957),
    new Peg(0.35057, 1.07856),
    new Peg(0.35279, 0.79302),
    new Peg(0.35286, 0.20343),
    new Peg(0.35286, 0.15165),
    new Peg(0.35427, 0.38911),
    new Peg(0.35723, 1.17917),
    new Peg(0.35871, 0.54224),
    new Peg(0.35945, 0.4912),
    new Peg(0.36315, 0.70721),
    new Peg(0.36536, 0.92469),
    new Peg(0.36758, 0.65542),
    new Peg(0.37054, 0.59032),
    new Peg(0.37136, 0.10283),
    new Peg(0.37136, 0.25374),
    new Peg(0.37276, 0.44089),
    new Peg(0.37572, 1.03492),
    new Peg(0.3846, 1.11333),
    new Peg(0.38608, 0.84702),
    new Peg(0.40087, 0.74715),
    new Peg(0.40169, 0.17828),
    new Peg(0.40235, 0.79893),
    new Peg(0.40383, 1.16067),
    new Peg(0.40383, 0.51561),
    new Peg(0.40391, 0.29368),
    new Peg(0.40465, 0.06436),
    new Peg(0.40753, 0.40169),
    new Peg(0.40827, 0.89658),
    new Peg(0.41049, 0.62953),
    new Peg(0.41049, 0.68427),
    new Peg(0.41123, 1.06968),
    new Peg(0.41345, 0.56665),
    new Peg(0.41426, 0.22858),
    new Peg(0.415, 0.12798),
    new Peg(0.41715, 0.46457),
    new Peg(0.43054, 0.33733),
    new Peg(0.43268, 1.12073),
    new Peg(0.44008, 0.8559),
    new Peg(0.44977, 0.03921),
    new Peg(0.45051, 0.26557),
    new Peg(0.45051, 0.09247),
    new Peg(0.45118, 0.80707),
    new Peg(0.45118, 0.75455),
    new Peg(0.45192, 0.60216),
    new Peg(0.45414, 0.38023),
    new Peg(0.45414, 0.65394),
    new Peg(0.45487, 0.70573),
    new Peg(0.45561, 0.43276),
    new Peg(0.45709, 0.54076),
    new Peg(0.45717, 0.15461),
    new Peg(0.45791, 0.20491),
    new Peg(0.45931, 0.49194),
    new Peg(0.47485, 1.1947),
    new Peg(0.47559, 1.14588),
    new Peg(0.47559, 1.09706),
    new Peg(0.47707, 1.04749),
    new Peg(0.47707, 0.99867),
    new Peg(0.47707, 0.90102),
    new Peg(0.47781, 0.94984),
    new Peg(0.47862, 0.32549),
    new Peg(0.50007, 0.08137),
    new Peg(0.50081, 0.03107),
    new Peg(0.50155, 0.13242),
    new Peg(0.50155, 0.28185),
    new Peg(0.50222, 0.56813),
    new Peg(0.50222, 0.81225),
    new Peg(0.50229, 0.18272),
    new Peg(0.50296, 0.7146),
    new Peg(0.50296, 0.61696),
    new Peg(0.50296, 0.76343),
    new Peg(0.50303, 0.36988),
    new Peg(0.50303, 0.23228),
    new Peg(0.5037, 0.51931),
    new Peg(0.5037, 0.46974),
    new Peg(0.50444, 0.66578),
    new Peg(0.50444, 0.86107),
    new Peg(0.50451, 0.42018),
    new Peg(0.52589, 1.09558),
    new Peg(0.52589, 1.1444),
    new Peg(0.52663, 0.99719),
    new Peg(0.52663, 1.04675),
    new Peg(0.52663, 0.94763),
    new Peg(0.52737, 1.19322),
    new Peg(0.52811, 0.32475),
    new Peg(0.53403, 0.89954),
    new Peg(0.54446, 0.15683),
    new Peg(0.54586, 0.54298),
    new Peg(0.54668, 0.20713),
    new Peg(0.54734, 0.4912),
    new Peg(0.54964, 0.26483),
    new Peg(0.5503, 0.38171),
    new Peg(0.5503, 0.60068),
    new Peg(0.5503, 0.80559),
    new Peg(0.55038, 0.09247),
    new Peg(0.55038, 0.04069),
    new Peg(0.55104, 0.70573),
    new Peg(0.55178, 0.65616),
    new Peg(0.55326, 0.43276),
    new Peg(0.55844, 0.75529),
    new Peg(0.56362, 0.85738),
    new Peg(0.57102, 1.11555),
    new Peg(0.57701, 0.34029),
    new Peg(0.58663, 0.12946),
    new Peg(0.58803, 0.56813),
    new Peg(0.58958, 0.23154),
    new Peg(0.59025, 0.46679),
    new Peg(0.59321, 0.8988),
    new Peg(0.59395, 1.15846),
    new Peg(0.59402, 0.06584),
    new Peg(0.59543, 0.40243),
    new Peg(0.5955, 0.29294),
    new Peg(0.59617, 0.62731),
    new Peg(0.59617, 0.68501),
    new Peg(0.59617, 1.06747),
    new Peg(0.5992, 0.17828),
    new Peg(0.60061, 0.79819),
    new Peg(0.60061, 0.51561),
    new Peg(0.61096, 0.74789),
    new Peg(0.61836, 0.84702),
    new Peg(0.61984, 1.11407),
    new Peg(0.62879, 0.10357),
    new Peg(0.6302, 0.43941),
    new Peg(0.6302, 0.59254),
    new Peg(0.63094, 1.03344),
    new Peg(0.63249, 0.25596),
    new Peg(0.63611, 0.65542),
    new Peg(0.63685, 0.92543),
    new Peg(0.64055, 1.17843),
    new Peg(0.64203, 0.70868),
    new Peg(0.64499, 0.54224),
    new Peg(0.64573, 0.48972),
    new Peg(0.64581, 0.20491),
    new Peg(0.64647, 0.38985),
    new Peg(0.64729, 0.15239),
    new Peg(0.65017, 0.79598),
    new Peg(0.65461, 1.0793),
    new Peg(0.65831, 0.88031),
    new Peg(0.6657, 1.12887),
    new Peg(0.66866, 0.75011),
    new Peg(0.67162, 0.61843),
    new Peg(0.6731, 1.00533),
    new Peg(0.67828, 0.4298),
    new Peg(0.67902, 0.83518),
    new Peg(0.68716, 0.93653),
    new Peg(0.68864, 0.69389),
    new Peg(0.68864, 0.36174),
    new Peg(0.68864, 1.17177),
    new Peg(0.6916, 0.52966),
    new Peg(0.69308, 0.4801),
    new Peg(0.69603, 1.05341),
    new Peg(0.69899, 0.78858),
    new Peg(0.70935, 0.88697),
    new Peg(0.71009, 1.10519),
    new Peg(0.71009, 0.64876),
    new Peg(0.71157, 0.31366),
    new Peg(0.71897, 0.74271),
    new Peg(0.71897, 0.98535),
    new Peg(0.72636, 0.39503),
    new Peg(0.72784, 0.82926),
    new Peg(0.72784, 0.44459),
    new Peg(0.73228, 1.15032),
    new Peg(0.73894, 1.02974),
    new Peg(0.73894, 0.69833),
    new Peg(0.7419, 0.93357),
    new Peg(0.74338, 0.5415),
    new Peg(0.74782, 0.34768),
    new Peg(0.74782, 0.78414),
    new Peg(0.75078, 1.07708),
    new Peg(0.753, 0.28185),
    new Peg(0.75596, 0.87143),
    new Peg(0.75743, 0.48528),
    new Peg(0.75965, 0.65394),
    new Peg(0.77075, 0.98165),
    new Peg(0.77223, 1.12147),
    new Peg(0.77445, 0.4298),
    new Peg(0.77667, 0.73162),
    new Peg(0.78702, 1.03418),
    new Peg(0.78702, 0.37949),
    new Peg(0.78702, 0.9099),
    new Peg(0.7885, 0.82926),
    new Peg(0.79146, 0.55925),
    new Peg(0.79294, 0.32031),
    new Peg(0.79664, 0.77896),
    new Peg(0.80034, 0.68353),
    new Peg(0.80108, 0.26779),
    new Peg(0.80182, 0.51117),
    new Peg(0.80478, 1.08226),
    new Peg(0.8107, 0.46309),
    new Peg(0.81883, 0.98831),
    new Peg(0.82031, 0.87217),
    new Peg(0.82179, 0.415),
    new Peg(0.82919, 0.72422),
    new Peg(0.83511, 0.36692),
    new Peg(0.83733, 1.04527),
    new Peg(0.84251, 0.82261),
    new Peg(0.84473, 0.55925),
    new Peg(0.84473, 0.31809),
    new Peg(0.84473, 0.7723),
    new Peg(0.85064, 0.26779),
    new Peg(0.85286, 0.50821),
    new Peg(0.85804, 0.44829),
    new Peg(0.86544, 1.00311),
    new Peg(0.87136, 0.40169),
    new Peg(0.89059, 0.34029),
    new Peg(0.89207, 0.54372),
    new Peg(0.89577, 0.47936),
    new Peg(0.90021, 0.28702),
    new Peg(0.91648, 0.38467),
    new Peg(0.9187, 0.43498),
    new Peg(0.93128, 0.51265),
    new Peg(0.93719, 0.32179),
    new Peg(0.95939, 0.46974),
    new Peg(0.96161, 0.36692),
    new Peg(0.96826, 0.41722),
  ]);
}
