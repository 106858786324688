import { Manufacturer } from '../manufacturer';
import { Peg, Pegboard } from '../pegboard';

function minDistance(pegs: Array<Peg>): number {
  let minDistance = 99999;

  for (var i = 0; i < pegs.length; i++) {
    for (var j = i + 1; j < pegs.length; j++) {
      minDistance = Math.min(
        minDistance,
        Math.sqrt(
          Math.pow(pegs[i].x - pegs[j].x, 2) +
            Math.pow(pegs[i].y - pegs[j].y, 2)
        )
      );
    }
  }
  return minDistance;
}

export function normalizedPegboard(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadSize: number,
  pegs: Array<Peg>
): Pegboard {
  const minDistanceBefore = minDistance(pegs);

  // Scale so that all pegs are at least 1 beadSize apart
  const scale = beadSize / minDistanceBefore;
  pegs = pegs.map((p) => new Peg(p.x * scale, p.y * scale));

  // Translate so that there's a beadSize / 2 border left and top
  pegs = translateToTheTopLeft(pegs, beadSize);

  return new Pegboard(manufacturer, manufacturerId, pegs, beadSize, 0, null);
}

export function translateToTheTopLeft(pegs: Peg[], border: number) {
  const minX = Math.min(...pegs.map((p) => p.x));
  const minY = Math.min(...pegs.map((p) => p.y));
  return pegs.map((p) => new Peg(p.x + (border - minX), p.y + (border - minY)));
}

export function rotateAndTranslateToTheTopLeft(
  pegs: Peg[],
  rotationRadian: number,
  border: number
) {
  let newPegs = pegs.map((p) => {
    const newX =
      p.x * Math.cos(rotationRadian) - p.y * Math.sin(rotationRadian);
    const newY =
      p.x * Math.sin(rotationRadian) + p.y * Math.cos(rotationRadian);
    return new Peg(newX, newY);
  });
  return translateToTheTopLeft(newPegs, border);
}
