import { Manufacturer } from './manufacturer';
import { createBoy } from './pegboard_templates/boy';
import { createButterfly } from './pegboard_templates/butterfly';
import { createCat } from './pegboard_templates/cat';
import { createCircle } from './pegboard_templates/circle';
import { createCrocodile } from './pegboard_templates/crocodile';
import { createDolphin } from './pegboard_templates/dolphin';
import { createEgg } from './pegboard_templates/egg';
import { createFlower } from './pegboard_templates/flower';
import { createFrog } from './pegboard_templates/frog';
import { createGirl } from './pegboard_templates/girl';
import { createHeart } from './pegboard_templates/heart';
import { createHexagon } from './pegboard_templates/hexagon';
import { createNumbers } from './pegboard_templates/numbers';
import { rotateAndTranslateToTheTopLeft } from './pegboard_templates/pegboard_normalizer';
import { createPrincess } from './pegboard_templates/princess';
import { createNonInterleavedRectangle } from './pegboard_templates/rectangles';
import { createSmallStar } from './pegboard_templates/stars';
import { createTeddy } from './pegboard_templates/teddy';

export class Peg {
  constructor(readonly x: number, readonly y: number) {}
}

export class Pegboard {
  readonly id: string;
  readonly width: number;
  readonly height: number;

  constructor(
    readonly manufacturer: Manufacturer,
    readonly manufacturerId: string,
    readonly pegs: Array<Peg>,
    readonly beadDiameter: number,
    readonly rotation: number,
    readonly rotationStep?: number | null
  ) {
    this.id = `${manufacturer.id}-${manufacturerId}`;

    const maxX = Math.max(...pegs.map((p) => p.x));
    const maxY = Math.max(...pegs.map((p) => p.y));
    this.width = maxX + this.beadDiameter;
    this.height = maxY + beadDiameter;
  }

  get ratio(): number {
    return this.width / this.height;
  }

  isAdjacent(peg1: Peg, peg2: Peg, thresholdDistance: number): boolean {
    if (peg1 === peg2) {
      return false;
    }
    return (
      Math.sqrt(Math.pow(peg1.x - peg2.x, 2) + Math.pow(peg1.y - peg2.y, 2)) <=
      thresholdDistance
    );
  }

  getAdjacentPegs(peg: Peg, thresholdDistance: number): Array<Peg> {
    return this.pegs.filter((p) => this.isAdjacent(peg, p, thresholdDistance));
  }

  rotate(angle: number): Pegboard {
    const rotationRadian = (angle / 180) * Math.PI;
    const newPegs = rotateAndTranslateToTheTopLeft(
      this.pegs,
      rotationRadian,
      this.beadDiameter
    );

    let newRotation = this.rotation + angle;
    newRotation = newRotation % 360;
    if (newRotation > 180) {
      newRotation = newRotation - 360;
    } else if (newRotation <= -180) {
      newRotation = newRotation + 360;
    }
    return new Pegboard(
      this.manufacturer,
      this.manufacturerId,
      newPegs,
      this.beadDiameter,
      newRotation,
      this.rotationStep
    );
  }
}

export function orderTopDownLeftRight(a: Peg, b: Peg) {
  if (a.y < b.y) return -1;
  if (a.y > b.y) return 1;
  if (a.x < b.x) return -1;
  if (a.x > b.x) return 1;
  return 0;
}

// All pegboards currently have a border of half a bead diameter,
// thats one bead diameter measured from the outer pegs
export const pegboards = [
  createNonInterleavedRectangle(Manufacturer.h, 'midi-220', 14, 14, 5),
  createNonInterleavedRectangle(Manufacturer.h, 'midi-234', 29, 29, 5),
  createHexagon(Manufacturer.h, 'midi-223', 8, 5),
  createHexagon(Manufacturer.h, 'midi-276', 16, 5),
  createSmallStar(Manufacturer.h, 'midi-270', 5),
  createCircle(Manufacturer.h, 'midi-222', 7, 5),
  createCircle(Manufacturer.h, 'midi-221', 14, 5),
  createHeart(Manufacturer.h, 'midi-236', 7, 2, 5),
  createHeart(Manufacturer.h, 'midi-233', 17, 2, 5),
  createButterfly(Manufacturer.h, 'midi-298', 5),
  createDolphin(Manufacturer.h, 'midi-300', 5),
  createEgg(Manufacturer.h, 'midi-260', 5),
  createFrog(Manufacturer.h, 'midi-225', 5),
  createPrincess(Manufacturer.h, 'midi-258', 5),
  createCrocodile(Manufacturer.h, 'midi-259', 5),
  createCat(Manufacturer.h, 'midi-288', 5),
  createNumbers(Manufacturer.h, 'midi-273', 5),
  createFlower(Manufacturer.h, 'midi-299', 5),
  createGirl(Manufacturer.h, 'midi-285', 5),
  createBoy(Manufacturer.h, 'midi-286', 5),
  createTeddy(Manufacturer.h, 'midi-289', 5),
];

export function getPegboardById(id: string): Pegboard {
  return pegboards.find((b) => b.id === id)!;
}
