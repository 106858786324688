import { generatePath, useParams } from 'react-router-dom';

export const fusePath = '/fuse/:id';

export type FusePathParams = {
  id: string;
};

export function fusePathForItem(itemId: string) {
  return generatePath(fusePath, { id: itemId });
}

export function useFusePathParams() {
  return useParams<FusePathParams>();
}
