import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';
import { normalizedPegboard } from './pegboard_normalizer';

export function createButterfly(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadSize: number
): Pegboard {
  return normalizedPegboard(manufacturer, manufacturerId, beadSize, [
    new Peg(0.03097, 0.13811),
    new Peg(0.04583, 0.09228),
    new Peg(0.05388, 0.18085),
    new Peg(0.07246, 0.22668),
    new Peg(0.0768, 0.1344),
    new Peg(0.08052, 0.05698),
    new Peg(0.09662, 0.2688),
    new Peg(0.10715, 0.09414),
    new Peg(0.10219, 0.18023),
    new Peg(0.10653, 0.31773),
    new Peg(0.11891, 0.22544),
    new Peg(0.12449, 0.13873),
    new Peg(0.1183, 0.36418),
    new Peg(0.12821, 0.05388),
    new Peg(0.13192, 0.62926),
    new Peg(0.13254, 0.58281),
    new Peg(0.14555, 0.26446),
    new Peg(0.14679, 0.67633),
    new Peg(0.14679, 0.54069),
    new Peg(0.14988, 0.18828),
    new Peg(0.15174, 0.31215),
    new Peg(0.15731, 0.10343),
    new Peg(0.15608, 0.4032),
    new Peg(0.16289, 0.3586),
    new Peg(0.1728, 0.14617),
    new Peg(0.17404, 0.05946),
    new Peg(0.17466, 0.22978),
    new Peg(0.17466, 0.50539),
    new Peg(0.17528, 0.59891),
    new Peg(0.17837, 0.71287),
    new Peg(0.18271, 0.64784),
    new Peg(0.1889, 0.27809),
    new Peg(0.19262, 0.55308),
    new Peg(0.19633, 0.32454),
    new Peg(0.19757, 0.18952),
    new Peg(0.20191, 0.41744),
    new Peg(0.20438, 0.09972),
    new Peg(0.20624, 0.37037),
    new Peg(0.21491, 0.68128),
    new Peg(0.21491, 0.47814),
    new Peg(0.21863, 0.6082),
    new Peg(0.21987, 0.14679),
    new Peg(0.22297, 0.23102),
    new Peg(0.22482, 0.73207),
    new Peg(0.22544, 0.05388),
    new Peg(0.22792, 0.52149),
    new Peg(0.23659, 0.27375),
    new Peg(0.23969, 0.33383),
    new Peg(0.24217, 0.56485),
    new Peg(0.2434, 0.64784),
    new Peg(0.24402, 0.1889),
    new Peg(0.2496, 0.37842),
    new Peg(0.2496, 0.11396),
    new Peg(0.25146, 0.42673),
    new Peg(0.25827, 0.69801),
    new Peg(0.25951, 0.4707),
    new Peg(0.26756, 0.60696),
    new Peg(0.26942, 0.06999),
    new Peg(0.27189, 0.30224),
    new Peg(0.27251, 0.74074),
    new Peg(0.27251, 0.24155),
    new Peg(0.27437, 0.51716),
    new Peg(0.28118, 0.1505),
    new Peg(0.29047, 0.64722),
    new Peg(0.28986, 0.56547),
    new Peg(0.28862, 0.19386),
    new Peg(0.29357, 0.38523),
    new Peg(0.29481, 0.34064),
    new Peg(0.30162, 0.42921),
    new Peg(0.30286, 0.47814),
    new Peg(0.30534, 0.27375),
    new Peg(0.30472, 0.68872),
    new Peg(0.30534, 0.1121),
    new Peg(0.31587, 0.6051),
    new Peg(0.31896, 0.06875),
    new Peg(0.3202, 0.5314),
    new Peg(0.31896, 0.22606),
    new Peg(0.32082, 0.73145),
    new Peg(0.33445, 0.18271),
    new Peg(0.33754, 0.39391),
    new Peg(0.33816, 0.3041),
    new Peg(0.34002, 0.65837),
    new Peg(0.34002, 0.34993),
    new Peg(0.34622, 0.48557),
    new Peg(0.34622, 0.43726),
    new Peg(0.34869, 0.26075),
    new Peg(0.35055, 0.1375),
    new Peg(0.35055, 0.5667),
    new Peg(0.35365, 0.03778),
    new Peg(0.35984, 0.61439),
    new Peg(0.36108, 0.69986),
    new Peg(0.36727, 0.21429),
    new Peg(0.37161, 0.52273),
    new Peg(0.3809, 0.17032),
    new Peg(0.384, 0.34498),
    new Peg(0.38585, 0.29853),
    new Peg(0.38585, 0.39019),
    new Peg(0.38771, 0.66146),
    new Peg(0.39143, 0.43664),
    new Peg(0.39081, 0.48247),
    new Peg(0.39205, 0.25207),
    new Peg(0.39514, 0.56113),
    new Peg(0.39824, 0.03097),
    new Peg(0.41001, 0.61625),
    new Peg(0.41311, 0.20438),
    new Peg(0.43354, 0.53202),
    new Peg(0.43354, 0.38771),
    new Peg(0.4354, 0.48371),
    new Peg(0.4354, 0.24402),
    new Peg(0.43107, 0.34188),
    new Peg(0.43169, 0.29419),
    new Peg(0.4354, 0.43416),
    new Peg(0.43726, 0.58033),
    new Peg(0.44036, 0.05326),
    new Peg(0.45708, 0.19262),
    new Peg(0.45894, 0.09476),
    new Peg(0.45894, 0.14307),
    new Peg(0.47814, 0.29295),
    new Peg(0.47814, 0.34002),
    new Peg(0.47876, 0.38585),
    new Peg(0.48, 0.43231),
    new Peg(0.47938, 0.24836),
    new Peg(0.48, 0.4769),
    new Peg(0.48, 0.52211),
    new Peg(0.47938, 0.5667),
    new Peg(0.50043, 0.11582),
    new Peg(0.50167, 0.60634),
    new Peg(0.50167, 0.16289),
    new Peg(0.50167, 0.20934),
    new Peg(0.52459, 0.5667),
    new Peg(0.52459, 0.52211),
    new Peg(0.52459, 0.4769),
    new Peg(0.52521, 0.24836),
    new Peg(0.52583, 0.38585),
    new Peg(0.52521, 0.43231),
    new Peg(0.52645, 0.34002),
    new Peg(0.52645, 0.29295),
    new Peg(0.54565, 0.14307),
    new Peg(0.54565, 0.09476),
    new Peg(0.5475, 0.19262),
    new Peg(0.56423, 0.05326),
    new Peg(0.56732, 0.58033),
    new Peg(0.57166, 0.29419),
    new Peg(0.56918, 0.43416),
    new Peg(0.56918, 0.24588),
    new Peg(0.57166, 0.34188),
    new Peg(0.56856, 0.48185),
    new Peg(0.57104, 0.53202),
    new Peg(0.57104, 0.38771),
    new Peg(0.59148, 0.20438),
    new Peg(0.59457, 0.61625),
    new Peg(0.60634, 0.03097),
    new Peg(0.60944, 0.56113),
    new Peg(0.61315, 0.25331),
    new Peg(0.61254, 0.48123),
    new Peg(0.61315, 0.43602),
    new Peg(0.61687, 0.66146),
    new Peg(0.61873, 0.29853),
    new Peg(0.61873, 0.39019),
    new Peg(0.62059, 0.34498),
    new Peg(0.62368, 0.1697),
    new Peg(0.63297, 0.52273),
    new Peg(0.63483, 0.21368),
    new Peg(0.6435, 0.69986),
    new Peg(0.64474, 0.61439),
    new Peg(0.65094, 0.03778),
    new Peg(0.65527, 0.56856),
    new Peg(0.65403, 0.1375),
    new Peg(0.65713, 0.26075),
    new Peg(0.65775, 0.43726),
    new Peg(0.65713, 0.48433),
    new Peg(0.66456, 0.65837),
    new Peg(0.66518, 0.34869),
    new Peg(0.66642, 0.3041),
    new Peg(0.66518, 0.39329),
    new Peg(0.67014, 0.18271),
    new Peg(0.68376, 0.73145),
    new Peg(0.68438, 0.5314),
    new Peg(0.685, 0.22668),
    new Peg(0.68562, 0.06875),
    new Peg(0.68872, 0.6051),
    new Peg(0.69924, 0.1121),
    new Peg(0.69986, 0.68872),
    new Peg(0.70048, 0.27127),
    new Peg(0.70172, 0.47876),
    new Peg(0.70296, 0.42921),
    new Peg(0.70915, 0.34064),
    new Peg(0.71101, 0.38523),
    new Peg(0.71535, 0.19509),
    new Peg(0.71411, 0.64598),
    new Peg(0.71473, 0.56547),
    new Peg(0.7234, 0.1505),
    new Peg(0.73145, 0.51778),
    new Peg(0.73269, 0.24031),
    new Peg(0.73207, 0.74074),
    new Peg(0.73269, 0.30224),
    new Peg(0.73517, 0.06999),
    new Peg(0.73702, 0.60696),
    new Peg(0.74508, 0.4707),
    new Peg(0.74631, 0.69801),
    new Peg(0.75313, 0.42673),
    new Peg(0.75499, 0.11396),
    new Peg(0.75437, 0.37842),
    new Peg(0.75932, 0.1889),
    new Peg(0.75932, 0.64722),
    new Peg(0.76242, 0.56485),
    new Peg(0.76428, 0.33383),
    new Peg(0.76799, 0.27375),
    new Peg(0.77666, 0.52149),
    new Peg(0.77914, 0.05388),
    new Peg(0.77976, 0.73207),
    new Peg(0.78162, 0.23102),
    new Peg(0.78471, 0.14679),
    new Peg(0.78595, 0.6082),
    new Peg(0.78967, 0.47814),
    new Peg(0.78967, 0.68128),
    new Peg(0.79834, 0.37037),
    new Peg(0.8002, 0.09972),
    new Peg(0.80268, 0.41744),
    new Peg(0.80701, 0.18952),
    new Peg(0.80825, 0.32454),
    new Peg(0.81197, 0.55308),
    new Peg(0.81568, 0.27809),
    new Peg(0.82188, 0.64784),
    new Peg(0.82621, 0.71287),
    new Peg(0.82931, 0.59891),
    new Peg(0.82993, 0.50539),
    new Peg(0.82993, 0.22978),
    new Peg(0.83055, 0.05946),
    new Peg(0.83178, 0.14617),
    new Peg(0.84169, 0.35736),
    new Peg(0.84665, 0.40196),
    new Peg(0.84913, 0.10343),
    new Peg(0.85284, 0.31215),
    new Peg(0.8547, 0.1889),
    new Peg(0.8578, 0.54069),
    new Peg(0.8578, 0.67633),
    new Peg(0.85904, 0.26446),
    new Peg(0.87204, 0.58281),
    new Peg(0.87266, 0.62926),
    new Peg(0.87638, 0.05388),
    new Peg(0.88629, 0.36542),
    new Peg(0.88071, 0.1375),
    new Peg(0.88629, 0.22606),
    new Peg(0.89806, 0.31773),
    new Peg(0.89867, 0.17961),
    new Peg(0.89991, 0.09538),
    new Peg(0.90796, 0.2688),
    new Peg(0.92407, 0.05698),
    new Peg(0.92778, 0.1344),
    new Peg(0.93212, 0.22668),
    new Peg(0.9507, 0.18085),
    new Peg(0.95875, 0.09228),
    new Peg(0.973, 0.13688),
  ]);
}
