/** Seed-able, non-secure random number generator. */
export default class Random {
  private readonly rand: () => number;

  constructor(seed: number) {
    var s = seed ^ 0xdeadbeef;

    this.rand = Random.sfc32(0x9e3779b9, 0x243f6a88, 0xb7e15162, s);
    // Warm it up
    for (var i = 0; i < 15; i++) this.rand();
  }

  nextDouble(): number {
    return this.rand();
  }

  nextInt(to: number): number {
    return Math.floor(this.nextDouble() * to);
  }

  // Simple Fast Counter random number generator
  private static sfc32(a: number, b: number, c: number, d: number) {
    return function () {
      a >>>= 0;
      b >>>= 0;
      c >>>= 0;
      d >>>= 0;
      var t = (a + b) | 0;
      a = b ^ (b >>> 9);
      b = (c + (c << 3)) | 0;
      c = (c << 21) | (c >>> 11);
      d = (d + 1) | 0;
      t = (t + d) | 0;
      c = (c + t) | 0;
      return (t >>> 0) / 4294967296;
    };
  }
}
