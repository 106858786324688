export function downloadCanvasAsPng(
  canvas: HTMLCanvasElement,
  fileName: string
) {
  const imgURI = canvas
    .toDataURL('image/png')
    .replace('image/png', 'image/octet-stream');
  const evt = new MouseEvent('click', {
    view: window,
    bubbles: false,
    cancelable: true,
  });
  const a = document.createElement('a');
  a.setAttribute('download', fileName);
  a.setAttribute('href', imgURI);
  a.setAttribute('target', '_blank');
  a.dispatchEvent(evt);
}
