import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';

export function createCircle(
  manufacturer: Manufacturer,
  manufacturerId: string,
  radius: number,
  beadSize: number
): Pegboard {
  const pegs: Array<Peg> = [];
  const centerPeg = new Peg(
    radius * beadSize + beadSize,
    radius * beadSize + beadSize
  );
  pegs.push(centerPeg);
  for (let r = 1; r <= radius; r++) {
    const numberOfPegInCircle = r * 6;
    for (let angleIx = 0; angleIx < numberOfPegInCircle; angleIx++) {
      const angle = ((2 * Math.PI) / numberOfPegInCircle) * angleIx;
      pegs.push(
        new Peg(
          Math.cos(angle) * r * beadSize + centerPeg.x,
          Math.sin(angle) * r * beadSize + centerPeg.y
        )
      );
    }
  }
  return new Pegboard(manufacturer, manufacturerId, pegs, beadSize, 0, null);
}
