import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import BeadPattern from '../bead_pattern/bead_pattern';
import { BeadPattern as BeadPatternModel } from '../model/bead_pattern';
import { Pegboard } from '../model/pegboard';

interface PegboardSelectItemProps {
  pegboard: Pegboard;
}

export default function PegboardSelectItem({
  pegboard,
}: PegboardSelectItemProps) {
  const { t } = useTranslation();
  return (
    <Box pad={'small'} margin={'auto'}>
      <Text alignSelf="center" margin={{ top: 'small' }}>
        {t(`pegboard.model.${pegboard.id}.name`)}
      </Text>
      <div style={{ height: '10vh', minHeight: 128 }}>
        <BeadPattern
          beadPattern={BeadPatternModel.empty(pegboard, undefined)}
        />
      </div>
    </Box>
  );
}
