import { Manufacturer } from '../manufacturer';
import { Pegboard, Peg } from '../pegboard';
import { normalizedPegboard } from './pegboard_normalizer';

export function createBoy(
  manufacturer: Manufacturer,
  manufacturerId: string,
  beadSize: number
): Pegboard {
  return normalizedPegboard(manufacturer, manufacturerId, beadSize, [
    new Peg(0.11264, 0.80627),
    new Peg(0.11474, 0.87354),
    new Peg(0.11789, 0.94607),
    new Peg(0.13051, 1.59361),
    new Peg(0.17255, 0.77158),
    new Peg(0.17466, 0.90823),
    new Peg(0.17466, 0.8399),
    new Peg(0.19042, 1.63355),
    new Peg(0.19778, 1.55997),
    new Peg(0.23037, 0.66856),
    new Peg(0.23352, 1.50215),
    new Peg(0.23352, 0.80627),
    new Peg(0.23352, 0.73584),
    new Peg(0.23562, 0.87354),
    new Peg(0.26085, 1.63145),
    new Peg(0.26611, 1.3697),
    new Peg(0.26716, 1.30243),
    new Peg(0.26716, 1.43803),
    new Peg(0.26716, 1.56102),
    new Peg(0.29239, 1.16998),
    new Peg(0.29344, 1.23725),
    new Peg(0.2987, 0.66856),
    new Peg(0.3008, 0.59498),
    new Peg(0.3029, 0.73584),
    new Peg(0.3029, 1.50005),
    new Peg(0.30395, 0.80416),
    new Peg(0.31552, 0.25018),
    new Peg(0.31762, 0.1787),
    new Peg(0.31972, 0.32061),
    new Peg(0.32813, 1.10796),
    new Peg(0.32813, 1.03858),
    new Peg(0.32918, 1.6283),
    new Peg(0.33444, 1.30243),
    new Peg(0.33549, 1.43908),
    new Peg(0.33654, 1.56102),
    new Peg(0.33654, 1.37286),
    new Peg(0.36072, 0.62861),
    new Peg(0.36282, 1.16788),
    new Peg(0.36387, 0.97971),
    new Peg(0.36387, 1.23936),
    new Peg(0.36492, 0.91244),
    new Peg(0.36492, 0.70535),
    new Peg(0.36492, 0.77263),
    new Peg(0.36597, 0.84306),
    new Peg(0.37018, 0.56029),
    new Peg(0.37228, 1.499),
    new Peg(0.37648, 0.14296),
    new Peg(0.37648, 0.28277),
    new Peg(0.37859, 0.21444),
    new Peg(0.37859, 0.3553),
    new Peg(0.37964, 0.42573),
    new Peg(0.39646, 1.10796),
    new Peg(0.39856, 1.03963),
    new Peg(0.40487, 1.44013),
    new Peg(0.40592, 1.30138),
    new Peg(0.40697, 1.37076),
    new Peg(0.42904, 0.59918),
    new Peg(0.4301, 0.9734),
    new Peg(0.4301, 0.74635),
    new Peg(0.4322, 0.89667),
    new Peg(0.4322, 0.82203),
    new Peg(0.4322, 1.17103),
    new Peg(0.43325, 0.67487),
    new Peg(0.43325, 1.23831),
    new Peg(0.43535, 0.11038),
    new Peg(0.43535, 0.17975),
    new Peg(0.4364, 0.24808),
    new Peg(0.43745, 0.38999),
    new Peg(0.43851, 0.45937),
    new Peg(0.43851, 0.31746),
    new Peg(0.43956, 0.5277),
    new Peg(0.46479, 1.10901),
    new Peg(0.46584, 1.04068),
    new Peg(0.49632, 0.3532),
    new Peg(0.49632, 0.14506),
    new Peg(0.49737, 0.42258),
    new Peg(0.49842, 0.91244),
    new Peg(0.49842, 0.56344),
    new Peg(0.49842, 0.21444),
    new Peg(0.49842, 0.28487),
    new Peg(0.49947, 0.98076),
    new Peg(0.49947, 0.49301),
    new Peg(0.50053, 0.77368),
    new Peg(0.50053, 0.84411),
    new Peg(0.50158, 0.63282),
    new Peg(0.50158, 1.16998),
    new Peg(0.50368, 0.70115),
    new Peg(0.53627, 1.10691),
    new Peg(0.53627, 1.03753),
    new Peg(0.55519, 0.10932),
    new Peg(0.55729, 0.18081),
    new Peg(0.55729, 0.32061),
    new Peg(0.55834, 0.25124),
    new Peg(0.55834, 0.38999),
    new Peg(0.55834, 0.52875),
    new Peg(0.55939, 0.45832),
    new Peg(0.5678, 0.9734),
    new Peg(0.5678, 0.89772),
    new Peg(0.56885, 0.82203),
    new Peg(0.5699, 0.7453),
    new Peg(0.57096, 0.60128),
    new Peg(0.57201, 0.67381),
    new Peg(0.57306, 1.23515),
    new Peg(0.57411, 1.16577),
    new Peg(0.60039, 1.44119),
    new Peg(0.60039, 1.37076),
    new Peg(0.60039, 1.29927),
    new Peg(0.60249, 1.03858),
    new Peg(0.6067, 1.10586),
    new Peg(0.61721, 0.42153),
    new Peg(0.61721, 0.21549),
    new Peg(0.61721, 0.28698),
    new Peg(0.61826, 0.3532),
    new Peg(0.61826, 0.14401),
    new Peg(0.62877, 0.56029),
    new Peg(0.63508, 0.62966),
    new Peg(0.63508, 1.5011),
    new Peg(0.63823, 0.77052),
    new Peg(0.63823, 0.70115),
    new Peg(0.64033, 0.97446),
    new Peg(0.64139, 0.8378),
    new Peg(0.64139, 0.90718),
    new Peg(0.64349, 1.16472),
    new Peg(0.64664, 1.23725),
    new Peg(0.66872, 1.36865),
    new Peg(0.66977, 1.56102),
    new Peg(0.67082, 1.30033),
    new Peg(0.67187, 1.03543),
    new Peg(0.67397, 1.43593),
    new Peg(0.67607, 0.24913),
    new Peg(0.67713, 0.1787),
    new Peg(0.67818, 1.10375),
    new Peg(0.67818, 1.62935),
    new Peg(0.67818, 0.31746),
    new Peg(0.69289, 0.59392),
    new Peg(0.6971, 0.66541),
    new Peg(0.6992, 0.73268),
    new Peg(0.70025, 0.80311),
    new Peg(0.70341, 1.50005),
    new Peg(0.71287, 1.16788),
    new Peg(0.71497, 1.23725),
    new Peg(0.7381, 1.36865),
    new Peg(0.74125, 1.55892),
    new Peg(0.7423, 1.43803),
    new Peg(0.7444, 1.30033),
    new Peg(0.75176, 1.6283),
    new Peg(0.76543, 0.6633),
    new Peg(0.76858, 0.86934),
    new Peg(0.76858, 0.73478),
    new Peg(0.76963, 0.80206),
    new Peg(0.77699, 1.5011),
    new Peg(0.81378, 1.55892),
    new Peg(0.82009, 1.62935),
    new Peg(0.8285, 0.8399),
    new Peg(0.8285, 0.90718),
    new Peg(0.8285, 0.76737),
    new Peg(0.87685, 1.59046),
    new Peg(0.88736, 0.80416),
    new Peg(0.88842, 0.94502),
    new Peg(0.88947, 0.87249),
  ]);
}
